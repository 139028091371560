import { HttpService } from "./http.service";

export class InvestmentListingsService extends HttpService {
  async getInvestmentListings(): Promise<{
    investment_listings: any;
  }> {
    return await this.fetchJson<{
      investment_listings: any;
    }>("/investment-portal/listings", "GET");
  }
}
