import { HttpService } from "./http.service";

export class TransactionService extends HttpService {
  async getCSV(transactionFormData: Record<string, any>): Promise<{
    transactions: any;
  }> {
    return await this.fetchJson<{
      transactions: any;
    }>("/user/download-transaction-report", "POST", transactionFormData);
  }

  async getTransactionData(transactionForm: Record<string, any>): Promise<{
    transaction_details: any;
  }> {
    return await this.fetchJson<{
      transaction_details: any;
    }>("/investment-portal/transaction-detail", "POST", transactionForm);
  }

  async updateTransactionData(transactionForm: Record<string, any>): Promise<{
    transaction_details: any;
    pagination: any;
  }> {
    return await this.fetchJson<{
      transaction_details: any;
      pagination: any;
    }>("/investment-portal/transaction-update", "POST", transactionForm);
  }
}
