import { HttpService } from "./http.service";
import {
  FormValidateAccountActivationToken,
  FormSetPassword,
  DefaultResponseRequest,
} from "../models/schemas/request-schema";

export class AccountActivateService extends HttpService {
  async validateAccountActivationToken(
    data: FormValidateAccountActivationToken
  ): Promise<DefaultResponseRequest> {
    return await this.fetchJson<DefaultResponseRequest>(
      "/user/validate-account-activation-token",
      "POST",
      data
    );
  }

  async setPassword(data: FormSetPassword): Promise<DefaultResponseRequest> {
    return await this.fetchJson<DefaultResponseRequest>(
      "/user/account-activate",
      "POST",
      data
    );
  }
}
