import { useState, useEffect } from "react";
import { SERVICES } from "../services";
import { useNavigate, useParams } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";
import {
  unitsDividedByMultiplier,
  getPercent,
  formatNumberToMoneyFormat,
  calcInvestorRate,
  calcEffectiveInvestorRate,
} from "../utils";
import {
  utils,
  Table,
  BasicRow,
  TextCell,
  CellHeader,
  RawCell,
  ActionButton,
  ActionButtonsCell,
  Notifications,
} from "@truenorthmortgage/olympus";
import Loading from "../components/Loading";
import NavComponent from "../components/NavComponent";
import NotificationComponent from "../components/NotificationComponent";
import BreadcrumbsComponent from "../components/BreadcrumbsComponent";
import { PhoneLink } from "../components";
import { InvestmentDetailsService } from "../services/investment-details-service";
import KratosModal from "../components/Modals/KratosModal";
import InvestmentListingInfo from "../components/InvestmentListingInfo";

const InvestmentOwnershipDetails = () => {
  const navigate = useNavigate();

  const [userName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // Grab the param from the route !
  const { investment_uuid } = useParams();

  /* eslint-disable */
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  /* eslint-enable */

  interface Investment {
    interest_rate: string;
    servicing_rate: string;
    investment: {
      uuid: string;
      source_reference: string;
      type: string;
      units_available: number;
      units_multiplier: string;
      interest_rate: string;
      servicing_rate: string;
      status: string;
      funded_date: string;
      created_at: string;
      updated_at: string;
      internal_id: number;
      investment_issuer_identifier: string;
      interest_type: string;
      variance: string;
      investment_grade: string;
      investment_issuer_uuid: string;
      investment_source_uuid: string;
      friendly_id: string;
      status_label: string;
      interest_type_label: string;
      investment_grade_label: string;
      type_label: string;
      holdback_days: null | number;
      current_ownership_certificate: OwnershipCertificate[];
    };
    issuer_ownership_certificate_owner: {
      investor_account_uuid: string;
      name: string;
      ownership_certificate_uuid: string;
    };
    investment_listings: InvestmentListing[];
    issuer: {
      uuid: string;
      name: string;
      url: string;
      required_ownership_percentage: string;
      status: string;
      created_at: string;
      updated_at: string;
      investor_account_uuid: string;
      default_investment_account_uuid: string;
    };
    investment_details: {
      uuid: string;
      investment_uuid: string;
      security: string;
      ltv: string;
      weighted_ltv: string;
      closing_date: string;
      maturity_date: string;
      payment_frequency: string;
      payment_date: string;
      payments_held: boolean;
      discharge_date: null | string;
      discharge_reason: string;
      subject_property_uuid: string;
      status: string;
      created_at: string;
      updated_at: string;
    };
    property: {
      uuid: string;
      title: string;
      street_address_1: string;
      street_address_2: null | string;
      city: string;
      province: string;
      postal_code: string;
      country: string;
      neighbourhood: string;
      neighbourhood_lat: string;
      neighbourhood_lon: string;
      status: string;
      created_at: string;
      updated_at: string;
      property_type: string;
      property_use: string;
    };
    borrower: Borrower[];
    note: Note[];
    max_units_available: number;
    required_units_owned_by_issuer: number;
    total_units_listed: string;
    total_units_sold: string;
  }

  interface OwnershipCertificate {
    uuid: string;
    investment_uuid: string;
    investor_account_uuid: string;
    investment_account_uuid: string;
    holder_type: string;
    investment_type: string;
    issued_reason: string;
    units: number;
    price_per_unit: number;
    rate_modifier: string;
    valid_from: string;
    valid_to: null | string;
    created_at: string;
    updated_at: string;
    holder_type_label: string;
    investment_type_label: string;
    issued_reason_label: string;
    owner: string;
    purchase_orders: any[];
    investment_listings: any[];
    investment: Investment["investment"];
  }

  interface InvestmentListing {
    uuid: string;
    ownership_certificate_uuid: string;
    units_listed: number;
    unitsSold: number;
    unitsPending: number;
    unitsAvailableToBeSold: number;
    unitsAvailableToBeSoldConsideringPending: number;
    investment_uuid: string;
    unit_price: string;
    return_rate_modifier_bps: string;
    friendly_id: string;
    status: string;
    created_at: string;
    updated_at: string;
    investment_listing_investor_group: null;
    investment: Investment["investment"];
    effective_investor_rate: string;
    issuer: {
      uuid: string;
      name: string;
      url: string;
      required_ownership_percentage: string;
      status: string;
      created_at: string;
      updated_at: string;
      investor_account_uuid: string;
      default_investment_account_uuid: string;
    };
    total_investment_listings: null;
    effective_investor_rate_error: boolean;
    units_sold: number;
  }

  interface Borrower {
    uuid: string;
    firstname: string;
    lastname: string;
    credit_score: number;
    status: string;
    created_at: string;
    updated_at: string;
  }

  interface Note {
    uuid: string;
    user_uuid: string;
    internal: boolean;
    note: string;
    status: string;
    created_at: string;
    updated_at: string;
  }

  /* eslint-disable */
  const [investment, setInvestment] = useState<Investment | null>(null);
  const [investmentUuid] = useState(investment_uuid);
  const [totalOwnerShipUnits, setTotalOwnershipUnits] = useState(0);
  const [totalUnits, setTotalUnits] = useState(0);
  const [unitsMultiplier, setUnitsMultiplier] = useState(0);
  const [unitsAvailable, setUnitsAvailable] = useState(0);
  const [investmentGrade, setInvestmentGrade] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountNickname, setAccountNickname] = useState("");
  const [accountFriendlyId, setAccountFriendlyId] = useState("");
  const [totalInvested, setTotalInvested] = useState("");
  const [totalInvestedNumber, setTotalInvestedNumber] = useState(0);
  const [latitude, setLatitude] = useState("51.04118839124114");
  const [longitude, setLongitude] = useState("-114.07707813329152");
  const [location, setLocation] = useState("");
  const [security, setSecurity] = useState("");
  const [propertyUse, setPropertyUse] = useState("");
  const [weightedLtv, setWeightedLtv] = useState("");
  const [creditScore, setCreditScore] = useState("");
  const [rate, setRate] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [renewalDate, setRenewalDate] = useState("");
  const [totalUnitsListed, setTotalUnitsListed] = useState("");
  const [totalUnitsSold, setTotalUnitsSold] = useState("");
  const [totalPercentAvailable, setTotalPercentAvailable] = useState(0);
  const [note, setNote] = useState("");
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [totalUnitsLeft, setTotalUnitsLeft] = useState<number>(0);
  const [purchaseOrderData, setPurchaseOrderData] = useState({
    uuid: "",
    date_purchased: "",
    effective_rate: "",
    investment_type: "",
    purchase_order_id: "",
    investment_account: "",
    price_per_unit: "",
    total_price: "",
    units: "",
    units_multiplier: "",
    user_nickname: "",
    investment_account_nickname: "",
    type: "",
    friendly_id: "",
    morcado_id: "",
  });
  /* eslint-enable */

  const url = new URL(window.location.href);
  const investmentAccountUuid = url.searchParams.get("investment_account_uuid");

  const [isPurchaseOpen, setIsPurchaseOpen] = useState(false);
  const closeIsPurchaseOpen = () => {
    setIsPurchaseOpen(false);
  };

  const investmentDetailsService =
    utils.injection.useInjection<InvestmentDetailsService>(
      SERVICES.InvestmentDetailService
    );

  useEffect(() => {
    const submitValues = {
      uuid: investmentUuid ?? null,
      investment_account_uuid: investmentAccountUuid ?? null,
    };
    investmentDetailsService
      .getInvestmentData({ investmentDetailsForm: submitValues })
      .then((data) => {
        setPurchaseOrders(data?.purchase_orders);
        setInvestment(data?.investment);
        setAccountType(data?.investment?.type_label);
        setAccountFriendlyId(data?.investment?.friendly_id);
        setTotalInvested(data?.total_invested);
        setTotalInvestedNumber(data?.total_invested);
        setTotalUnits(data?.investment?.total_units_listed);
        setTotalOwnershipUnits(data?.total_ownership_units);
        setUnitsMultiplier(data?.investment?.units_multiplier);
        setUnitsAvailable(data?.investment?.units_available);
        setInvestmentGrade(data?.investment?.investment_grade);
        setLocation(
          data?.investment?.property?.neighbourhood +
            " ," +
            data?.investment?.property?.city +
            " ," +
            data?.investment?.property?.province
        );

        setSecurity(data?.investment?.investment_details?.security);
        setPropertyUse(data?.investment?.property?.property_use);
        setWeightedLtv(data?.investment?.investment_details?.weighted_ltv);
        setCreditScore(data?.investment?.borrower[0]?.credit_score);

        setRate(
          data?.investment?.investment_listings[0]?.effective_investor_rate
        );

        setPropertyType(
          data?.investment?.property?.property_type.replace(/_/g, " ")
        );

        setTotalUnitsListed(data?.investment?.total_units_listed);
        setTotalUnitsSold(data?.investment?.total_units_sold);
        setNote(data?.investment?.note[0]?.note);

        // Format the Maturity Date
        const dateString = data?.investment?.investment_details?.maturity_date; // Your date string
        const parts = dateString.split(/[\s-:]/); // Split the date string
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript
        const day = parseInt(parts[2], 10);

        const date = new Date(year, month, day);
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
          date
        );
        setRenewalDate(formattedDate);

        setIsLoading(false);

        setTotalUnitsLeft(
          data?.investment?.total_units_listed -
            data?.investment?.total_units_sold
        );
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [userName]);

  // Watch for changes in the purchase order to cause a re-render
  useEffect(() => {}, [purchaseOrderData]);

  // Calculate the percent available
  useEffect(() => {
    if (totalUnits && totalUnitsListed) {
      const percentAvailable =
        ((Number(totalUnitsListed) - Number(totalUnitsSold)) /
          Number(totalUnitsListed)) *
        100;
      setTotalPercentAvailable(percentAvailable);
    }
  }, [totalUnitsSold, totalUnitsListed]);

  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);

  // Get the previous breadcrumbs
  useEffect(() => {
    const additionalBreadCrumbs = [
      {
        label: "Dashboard",
        path: "/dashboard/",
      },
      {
        label: accountType + (accountFriendlyId ? " " + accountFriendlyId : ""),
        path: "/investment-account/" + investment_uuid,
      },
    ];
    const combinedBreadcrumbs = [...additionalBreadCrumbs];
    setBreadcrumbs(combinedBreadcrumbs);
  }, [accountFriendlyId, accountType, investment_uuid]);

  const updateModalData = (val: any) => {
    const effectiveRate = calcEffectiveInvestorRate(
      false,
      Number(investment?.interest_rate),
      Number(investment?.servicing_rate),
      Number(val?.rate_modifier)
    );

    setPurchaseOrderData({
      uuid: val?.uuid,
      date_purchased: val?.effective_date,
      effective_rate: String(effectiveRate),
      investment_type: val?.investment_type,
      purchase_order_id: val?.uuid,
      investment_account: val?.investment_account_friendly_id,
      price_per_unit: val?.price_per_unit,
      total_price: (val?.units * val?.price_per_unit).toString(),
      units: val?.units,
      units_multiplier: val?.units_multiplier,
      user_nickname: val?.user_nickname,
      investment_account_nickname: val?.investment_account_nickname,
      type: val?.type,
      friendly_id: val?.friendly_id,
      morcado_id: val?.morcado_id,
    });
    setIsPurchaseOpen(true);
  };

  const viewListings = () => {
    navigate("/investment-listings");
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="dashboard-container">
        <NavComponent />
      </div>
      <div id="main" className="dashboard-content">
        <Notifications
          timeout={15000}
          customNotificationElement={<NotificationComponent />}
        />
        <div className="main-header">
          <BreadcrumbsComponent items={breadcrumbs} />s
        </div>
        <div className="main-content">
          <div className="dashboard">
            <h1
              style={{ textTransform: "capitalize", marginBottom: "3px" }}
              className={"mobile-header"}
            >
              Investment and Ownership Details
            </h1>

            <h2 data-dd-privacy="mask">
              {accountType + " " + accountFriendlyId}
            </h2>
            <div className="divider" />
            <Table className="primary overview-table table-no-display-block">
              <BasicRow key={"total-invested-row"}>
                <TextCell>Total Invested</TextCell>
                <TextCell>
                  <span data-dd-privacy="mask" className={"data-dog-wrap"}>
                    ${formatNumberToMoneyFormat(Number(totalInvested), 2)}
                  </span>
                </TextCell>
              </BasicRow>
              <BasicRow key={"total-ownership-row"}>
                <TextCell key={"total-ownership-title"}>
                  Total Ownership
                </TextCell>
                <TextCell key={"total-ownership-value"}>
                  <div
                    key={investmentUuid + "percent-bar-component"}
                    style={{ marginBottom: "5px" }}
                  >
                    {getPercent(
                      totalInvestedNumber,
                      unitsDividedByMultiplier(
                        Number(totalUnitsSold),
                        unitsMultiplier
                      ),
                      false
                    )}
                    %
                  </div>
                  <ProgressBar
                    key={investmentUuid + "progress-bar-component"}
                    active={
                      unitsDividedByMultiplier(
                        totalOwnerShipUnits,
                        unitsMultiplier
                      ) ?? 0
                    }
                    total={
                      unitsDividedByMultiplier(
                        unitsAvailable,
                        unitsMultiplier
                      ) ?? 0
                    }
                    showMax={false}
                    showText={false}
                  />
                </TextCell>
              </BasicRow>
            </Table>

            <h2>Purchase History</h2>
            <div className="divider" />

            {/*Create the Modals */}
            <KratosModal
              is_open={isPurchaseOpen}
              callback={closeIsPurchaseOpen}
              key={"kratos-modal"}
            >
              <div className="purchase-order-modal">
                <h1 className="main-header-modal-header mobile-header">
                  Purchase Order Details
                </h1>
                <div className="modal-content">
                  <h2>{purchaseOrderData?.uuid}</h2>
                  <div className="separator">
                    <div className="left-side-modal">
                      <div className="data-wrap">
                        <div className="modal-label">Date Purchased</div>
                        <span data-dd-privacy="mask">
                          {purchaseOrderData?.date_purchased}
                        </span>
                      </div>
                      <div className="data-wrap">
                        <div className="modal-label">Effective Rate</div>
                        <span data-dd-privacy="mask">
                          {parseFloat(
                            purchaseOrderData?.effective_rate
                          ).toFixed(2)}
                          %
                        </span>
                      </div>
                      <div className="data-wrap">
                        <div className="modal-label">Investment Type</div>
                        <span className="capitalize">
                          {purchaseOrderData?.investment_type.replace(
                            /_/g,
                            " "
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="right-side-modal">
                      <div className="data-wrap">
                        <div className="modal-label">Purchase Order Id</div>
                        <div>{purchaseOrderData?.purchase_order_id}</div>
                      </div>
                      <div className="data-wrap">
                        <div className="modal-label">Investment Account</div>
                        <span
                          className={
                            "purchase-order-account-details span-no-width"
                          }
                        >
                          <span className={"flex-column-mobile"}>
                            <span
                              className={
                                purchaseOrderData?.type === "tfsa"
                                  ? "uppercase"
                                  : "capitalize"
                              }
                            >
                              {purchaseOrderData?.type}
                            </span>

                            <span
                              data-dd-privacy="mask"
                              className={"user-nickname"}
                            >
                              {purchaseOrderData?.user_nickname
                                ? "   - " + purchaseOrderData.user_nickname
                                : " "}
                            </span>
                          </span>

                          <span data-dd-privacy="mask" className={"morcado-id"}>
                            {purchaseOrderData?.morcado_id
                              ? purchaseOrderData?.morcado_id
                              : " "}
                          </span>
                        </span>
                      </div>

                      <div className="data-wrap">
                        <div className="modal-label">Total Price</div>
                        <span data-dd-privacy="mask">
                          $
                          {formatNumberToMoneyFormat(
                            unitsDividedByMultiplier(
                              Number(purchaseOrderData?.total_price),
                              Number(purchaseOrderData?.units_multiplier)
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <ActionButton
                    className="white-row purchase-order-button"
                    id={"close-modal"}
                    key={"close-purchase-order-modal"}
                    callback={closeIsPurchaseOpen}
                  >
                    Done
                  </ActionButton>
                </div>
              </div>
            </KratosModal>

            <Table
              className="primary investment-table investment-details-table"
              key={"investment-details-table-" + investmentUuid}
              isEmpty={
                purchaseOrders === undefined || purchaseOrders.length === 0
              }
            >
              <CellHeader
                key={"investment-details-table-header" + investmentUuid}
                className="table-header"
              >
                <RawCell key={"account-col" + investmentUuid}>Account</RawCell>
                <RawCell key={"date-col" + investmentUuid}>Date</RawCell>
                <RawCell key={"rate-col" + investmentUuid}>Rate</RawCell>
                <RawCell key={"units-col" + investmentUuid}>Units</RawCell>
                <RawCell key={"button-col"}></RawCell>
              </CellHeader>
              {purchaseOrders &&
                Array.isArray(purchaseOrders) &&
                purchaseOrders.map((order: any) => (
                  <BasicRow
                    key={
                      Math.random() +
                      "invesment-purchase-table-data" +
                      order?.uuid
                    }
                    className="table-rows white-row"
                  >
                    <RawCell
                      key={"data" + order?.uuid}
                      className={"investment-ownership-table-row"}
                    >
                      <span className={"purchase-order-account-details"}>
                        <span
                          className={
                            order?.type === "tfsa" ? "uppercase" : "capitalize"
                          }
                        >
                          {order?.type}

                          <span
                            data-dd-privacy="mask"
                            className={"user-nickname"}
                          >
                            {order?.user_nickname
                              ? "  - " + order.user_nickname
                              : " "}
                          </span>
                        </span>

                        <span data-dd-privacy="mask" className={"morcado-id"}>
                          {order?.morcado_id ? order?.morcado_id : " "}
                        </span>
                      </span>
                    </RawCell>
                    <RawCell key={order?.effective_date + "date"}>
                      {order?.effective_date}
                    </RawCell>
                    <RawCell key={order?.rate_modifier + "rate_modifier"}>
                      <span data-dd-privacy="mask">
                        {calcInvestorRate(
                          investment?.interest_rate,
                          investment?.servicing_rate
                        )}
                        %
                      </span>
                    </RawCell>
                    <RawCell key={order?.units + "units"}>
                      <span data-dd-privacy="mask">
                        $
                        {formatNumberToMoneyFormat(
                          unitsDividedByMultiplier(
                            order?.units,
                            order?.units_multiplier
                          )
                        )}
                      </span>
                    </RawCell>
                    <ActionButtonsCell
                      key={order?.uuid + "buttons"}
                      className="white-row purchase-order-button investment-ownership-view-more-listings"
                    >
                      <ActionButton
                        id={"purchase-order"}
                        key={"purchase-order-" + order?.uuid}
                        callback={() => updateModalData(order)}
                      >
                        <i className="fa fa-file-lines"></i>
                        Purchase Order
                      </ActionButton>
                    </ActionButtonsCell>
                  </BasicRow>
                ))}
            </Table>
            {totalUnitsLeft === 0 ? (
              <></>
            ) : (
              <Table
                key={"no-listings-table"}
                className="primary investment-table investment-bottom-row"
              >
                <CellHeader
                  className="table-header"
                  key={"no-listings-table-header"}
                >
                  <RawCell key={"no-listings-table-data"}>
                    There are active listing for this investment
                  </RawCell>
                  <ActionButtonsCell
                    key={"no-listings-table-buttons"}
                    className="white-row green-button investment-details-cell investment-ownership-view-more-listings"
                  >
                    <ActionButton
                      id={"view-listing"}
                      key={"view-listing-no-data"}
                      className="view-listing green-button"
                      callback={viewListings}
                    >
                      <i className="fa fa-file-lines"></i>
                      View Listings
                    </ActionButton>
                  </ActionButtonsCell>
                </CellHeader>
              </Table>
            )}

            <h2 className={"investment-ownership-details-header"}>
              Investment Details
            </h2>
            <div className="divider" />
            <div className="investment-ownership-details-container">
              <InvestmentListingInfo
                investment={investment}
                investment_details={investment?.investment_details}
                property={investment?.property}
                borrowers={investment?.borrower}
                listing={investment?.investment_listings}
                notes={investment?.note}
              />
            </div>

            <div className="footer-content"></div>
          </div>
        </div>
        <div className="footer">
          <div className="footer__phone-link">
            Need help?
            <PhoneLink />
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestmentOwnershipDetails;
