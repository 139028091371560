import { HttpService } from "./http.service";

export class InvestmentDetailsService extends HttpService {
  async getInvestmentData(investmentData: Record<string, any>): Promise<{
    investment: any;
    total_invested: any;
    total_ownership_units: any;
    purchase_orders: any;
  }> {
    return await this.fetchJson<{
      investment: any;
      total_invested: any;
      total_ownership_units: any;
      purchase_orders: any;
    }>("/investment-portal/details", "POST", investmentData);
  }
}
