import { FC, ReactNode } from "react";

import logo from "../../images/logo-morcado.png";

interface LayoutInterface {
  greeting: string;
  className: string;
  children: ReactNode;
}

const displayVersion = "Investor Portal " + window.REACT_APP_VERSION;

const LoginLayout: FC<LayoutInterface> = ({
  className = "",
  greeting,
  children,
}) => {
  return (
    <div className="has-footer">
      <div className={className}>
        <div className="img-container">
          <a href="/" title={displayVersion} className={"login-logo"}>
            <img src={logo} className="app-logo" alt="logo" />
          </a>
        </div>
        <h1 className="greeting">{greeting}</h1>
        {children}
      </div>
    </div>
  );
};

export default LoginLayout;
