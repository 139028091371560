import { FC, FormEvent, useCallback, useEffect, useState } from "react";

import {
  Column,
  Container,
  MoneyComponent,
  SelectComponent,
  DateComponent,
  utils,
} from "@truenorthmortgage/olympus";
import {
  formatNumberToMoneyFormat,
  notify,
  notifyErrors,
  unitsDividedByMultiplier,
  unitsMultipliedByMultiplier,
  convertMoneyStringToInteger,
} from "../../../utils";
import { ERROR } from "../../../constants";
import { SERVICES } from "../../../services";
import { ActionQueueService } from "../../../services/action-queue-service";
import {
  ActionQueueCancelForm,
  DefaultResponseRequest,
  FundAccountResponse,
} from "../../../models/schemas/request-schema";
import { ActionQueueFundForm } from "../../../models/schemas/request-schema";
import Loading from "../../Loading";

export type NotAllowedToWithdrawModalProps = {
  callback?: any;
};

const NotAllowedToWithdrawModal: FC<NotAllowedToWithdrawModalProps> = ({
  callback = null,
}) => {
  return (
    <>
      <Container
        className={
          "generic-modal-container action-queue-fund-container not-allowed-to-withdraw"
        }
      >
        <Column columnStyle={"full no-padding-left"}>
          <div className="center">
            <h2>Account Withdrawal Not Available</h2>
          </div>
        </Column>

        <Column columnStyle={"full no-padding-left"}>
          <div className="center">
            <p>
              Self-serve account withdrawal is not available for this type of
              investment account.{" "}
            </p>
          </div>
        </Column>
        <Column columnStyle={"full no-padding-left"}>
          <div className="center">
            <p>
              For help accessing/transferring funds from this account, please
              contact us at <a href={"info@morcado.ca"}>info@morcado.ca</a>
            </p>
          </div>
        </Column>
        <Column columnStyle={"full no-padding-left"}>
          <button
            key="close_modal"
            type="button"
            className="button submit-button no-right-margin button-green"
            style={{ marginLeft: "auto" }}
            onClick={callback}
          >
            OK
          </button>
        </Column>
      </Container>
    </>
  );
};

export default NotAllowedToWithdrawModal;
