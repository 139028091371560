import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo-morcado.png";
import { utils } from "@truenorthmortgage/olympus";
import { AuthService } from "../services/auth.service";
import { SERVICES } from "../services";

const NavComponent = () => {
  const navigate = useNavigate();
  const authService = utils.injection.useInjection<AuthService>(
    SERVICES.AuthService
  );

  const closeNav = () => {
    const items = document.getElementById("nav-items");
    if (items) items.className = "";
  };

  const toggleNav = () => {
    const items = document.getElementById("nav-items");
    if (items) items.className = items.className === "open" ? "" : "open";
  };

  const logout = () => {
    authService.logOut(navigate);
  };

  const displayVersion = "Morcado Investor Portal " + window.REACT_APP_VERSION;

  return (
    <nav id="nav-bar">
      <div className="nav-bar">
        <div className="nav-bar-contents">
          <div></div>
          <a href="/dashboard" title={displayVersion}>
            <img src={logo} className="app-logo" alt="logo" />
          </a>
          <div className="nav-toggle" onClick={toggleNav}>
            <i className="fa fa-bars primary"></i>
          </div>
        </div>
      </div>
      <div id="nav-items" onClick={closeNav}>
        <div onClick={closeNav} className="close-button">
          <i className="fa fa-close"></i>
        </div>
        <ul>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link
              to={
                "/investor-account-details/" +
                localStorage.getItem("account_id")
              }
            >
              My Account
            </Link>
          </li>
          <li>
            <button className="link-button" onClick={() => logout()}>
              Logout
            </button>
          </li>
        </ul>
        <div className="external-links">
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.morcado.ca/utility/privacy-policy"
              >
                Privacy
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="nav-mask" onClick={closeNav} />
    </nav>
  );
};

export default NavComponent;
