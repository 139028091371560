import { FC } from "react";
import { formatNumber, getPercent } from "../utils";

export type TextComponentProps = {
  active?: number;
  draft?: number;
  total?: number;
  showMax?: boolean;
  showText?: boolean;
  style?: any;
  text?: any;
  showDollarSign?: boolean;
};

const ProgressBar: FC<TextComponentProps> = ({
  active = 0,
  draft = 0,
  total = 0,
  showMax = true,
  showText = true,
  style = null,
  text = null,
  showDollarSign = false,
}) => {
  return (
    <>
      <div className="progress-wrap" style={style}>
        {showMax && (
          <div
            className="progress-bar-max"
            style={{
              left:
                (Number(getPercent(total, active + draft)) <= 0.0
                  ? 100
                  : getPercent(total, active + draft)) + "%",
            }}
          >
            <div>
              <span>Max</span>
            </div>
          </div>
        )}
        <div className="progress-bar">
          <div
            className="progress-bar-section section1"
            style={{ flexGrow: getPercent(active, total) }}
          ></div>
          <div
            className="progress-bar-section section2"
            style={{ flexGrow: getPercent(draft, total) }}
          ></div>
          <div
            className="progress-bar-section section3"
            style={{ flexGrow: getPercent(total - (active + draft), total) }}
          ></div>
        </div>
        {showText && (
          <div className="progress-bar-text">
            {showDollarSign === true ? "$" : ""}
            {formatNumber(active)}{" "}
            {text === null ? (
              <>
                <span className="color_section1">active</span> listings
              </>
            ) : (
              text[0]
            )}
            <br />
            {showDollarSign === true ? "$" : ""}
            {formatNumber(draft)}{" "}
            {text === null ? (
              <>
                <span className="color_section2">draft</span> listings
              </>
            ) : (
              text[1]
            )}
            <br />
            {showDollarSign === true ? "$" : ""}
            {formatNumber(total)}{" "}
            {text === null ? (
              <>
                <span className="bold">total</span> investment units
              </>
            ) : (
              text[2]
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProgressBar;
