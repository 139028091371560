export const SERVICES = {
  AuthService: "AuthService",
  LoginService: "LoginService",
  HttpService: "HttpService",
  DashboardPageService: "DashboardPageService",
  NotificationService: "NotificationService",
  InvestmentAccountService: "InvestmentAccountService",
  TransactionService: "TransactionService",
  ForgetPasswordService: "ForgetPasswordService",
  InvestmentDetailService: "InvestmentDetailService",
  InvestmentListingService: "InvestmentListingService",
  InvestorAccountService: "InvestorAccountService",
  ResetPasswordService: "ResetPasswordService",
  AccountActivateService: "AccountActivateService",
  ActionQueueService: "ActionQueueService",
};
