import { HttpService } from "./http.service";
import {
  FormForgetPassword,
  DefaultResponseRequest,
} from "../models/schemas/request-schema";

export class ForgetPasswordService extends HttpService {
  async sendResetPasswordEmail(
    data: FormForgetPassword
  ): Promise<DefaultResponseRequest> {
    return await this.fetchJson<DefaultResponseRequest>(
      "/user/forgot-password",
      "POST",
      data
    );
  }
}
