import { HttpService } from "./http.service";
import {
  FormValidatePasswordResetToken,
  FormResetPassword,
  DefaultResponseRequest,
} from "../models/schemas/request-schema";

export class ResetPasswordService extends HttpService {
  async validatePasswordResetToken(
    data: FormValidatePasswordResetToken
  ): Promise<DefaultResponseRequest> {
    return await this.fetchJson<DefaultResponseRequest>(
      "/user/validate-password-reset-token",
      "POST",
      data
    );
  }

  async resetPassword(
    data: FormResetPassword
  ): Promise<DefaultResponseRequest> {
    return await this.fetchJson<DefaultResponseRequest>(
      "/user/reset-password",
      "POST",
      data
    );
  }
}
