import { Dispatch } from "@reduxjs/toolkit";
import { FC, HTMLAttributes } from "react";
import { Notification } from "@truenorthmortgage/olympus";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

/* eslint-disable */
export interface BasicNotificationProps extends HTMLAttributes<HTMLElement> {
  notification?: Notification;
  closeNotification?: (
    notification: Notification,
    dispatch: Dispatch<any>
  ) => void;
}
/* eslint-enable */

const NotificationComponent: FC<BasicNotificationProps> = ({
  notification,
  closeNotification,
}) => {
  const [style, setStyle] = useState({
    height: "auto",
    opacity: 1,
  });

  useEffect(() => {
    setStyle({
      height: "auto",
      opacity: 1,
    });
  }, []);

  const toggleNotification = () => {
    setStyle({
      height: "auto",
      opacity: 0,
    });
  };

  return notification && closeNotification ? (
    <div className="notifications">
      <div className={`notification status-open`} style={style}>
        {notification.class === "success" && (
          <div className="notification-inner success">
            <i className="close">
              <FontAwesomeIcon icon={faClose} onClick={toggleNotification} />
            </i>
            <span>{notification.message}</span>
          </div>
        )}
        {notification.class === "error" && (
          <div className="notification-inner error">
            <i className="close">
              <FontAwesomeIcon icon={faClose} onClick={toggleNotification} />
            </i>
            <span>{notification.message}</span>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default NotificationComponent;
