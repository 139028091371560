import { useState, useEffect } from "react";
import { SERVICES } from "../services";
import { useNavigate, useParams } from "react-router-dom";

import {
  utils,
  Table,
  BasicRow,
  TextCell,
  CellHeader,
  RawCell,
  Notifications,
} from "@truenorthmortgage/olympus";
import Loading from "../components/Loading";
import NavComponent from "../components/NavComponent";
import NotificationComponent from "../components/NotificationComponent";
import BreadcrumbsComponent from "../components/BreadcrumbsComponent";
import { PhoneLink } from "../components";
import { InvestorAccountService } from "../services/investor-account-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InvestorAccountDetails = () => {
  const navigate = useNavigate();

  const [userName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const investorAccountService =
    utils.injection.useInjection<InvestorAccountService>(
      SERVICES.InvestorAccountService
    );

  const [investorAccountFriendlyId, setInvestorAccountFriendlyId] =
    useState("");
  const [investorAccountType, setInvestorAccountType] = useState("");
  const [investorName, setInvestorName] = useState("");
  const [contactDetails, setContactDetails] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);

  // Grab the param from the route !
  const { investor_account_uuid } = useParams();

  // Grab the Dashboard Data !
  // Link this data to values for display !
  useEffect(() => {
    const submitValues = {
      uuid: investor_account_uuid ?? null,
    };
    investorAccountService
      .getInvestorAccount({ investorAccountForm: submitValues })
      .then((data) => {
        setInvestorAccountFriendlyId(data?.investor_account?.friendly_id);
        setInvestorAccountType(data?.investor_account?.type);
        setInvestorName(data?.investor_account?.name);
        setContactDetails(data?.investor_account?.contactDetail);
        setBankAccounts(data?.investor_account?.bank_accounts);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [userName]);

  const breadcrumbs = [
    { label: "Dashboard", path: "/dashboard" },
    {
      label: "Investor Account Details",
      path: "/investor-account-details/" + investor_account_uuid,
    },
  ];

  /* eslint-disable */
  const investmentDetails = (val: any) => {
    navigate("/investment/" + val);
  };
  /* eslint-enable */

  // Store Entire BreadCrumb
  localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="dashboard-container">
        <NavComponent />
      </div>
      <div id="main" className="dashboard-content">
        <Notifications
          timeout={15000}
          customNotificationElement={<NotificationComponent />}
        />
        <div className="main-header">
          <BreadcrumbsComponent items={breadcrumbs} />s
        </div>
        <div className="main-content">
          <div className="dashboard">
            <h1
              style={{ textTransform: "capitalize", marginBottom: "3px" }}
              className={"mobile-header"}
            >
              Investor Account Details
            </h1>
            <h3>
              Personal Investor Account{" "}
              <span data-dd-privacy="mask">{investorAccountFriendlyId}</span>
            </h3>

            <h2>Overview</h2>
            <div className="divider" />
            <Table className="primary overview-table table-no-display-block">
              <BasicRow>
                <TextCell>Investor ID</TextCell>
                <TextCell data-dd-privacy="mask">
                  {investorAccountFriendlyId}
                </TextCell>
              </BasicRow>
              <BasicRow>
                <TextCell>Investor Account Type</TextCell>
                <TextCell className="capitalize" data-dd-privacy="mask">
                  {investorAccountType}
                </TextCell>
              </BasicRow>
              <BasicRow>
                <TextCell>Investor Name</TextCell>
                <TextCell>
                  <span data-dd-privacy="mask">{investorName}</span>
                </TextCell>
              </BasicRow>
            </Table>

            <h2>Contact Details</h2>
            <div className="divider" />
            <Table
              className={`primary overview-table ${
                contactDetails ? "" : "table-no-display-block"
              }`}
            >
              <CellHeader className="table-header">
                <RawCell className="black-bold">Type</RawCell>
                <RawCell className="black-bold">Label</RawCell>
                <RawCell className="black-bold">Value</RawCell>
              </CellHeader>

              {/* Loop through the contact details */}
              {contactDetails?.map((contact: any) => (
                <BasicRow rowClasses={["white-row"]} key={contact?.uuid}>
                  {contact?.type === "email" && (
                    <TextCell className={"contact-label"}>
                      <span className={"inner-wrap"}>
                        <FontAwesomeIcon icon={"at"} /> <span> Email</span>
                      </span>
                    </TextCell>
                  )}
                  {contact?.type === "phone" && (
                    <TextCell className={"contact-label"}>
                      <span className={"inner-wrap"}>
                        <FontAwesomeIcon icon={"phone"} /> <span> Phone</span>
                      </span>
                    </TextCell>
                  )}
                  {contact?.type === "address" && (
                    <TextCell className={"contact-label"}>
                      <span className={"inner-wrap"}>
                        <FontAwesomeIcon icon={"building"} />{" "}
                        <span> Address</span>
                      </span>
                    </TextCell>
                  )}
                  <TextCell>{contact?.label?.value}</TextCell>
                  {contact?.type === "email" && (
                    <RawCell>
                      <span data-dd-privacy="mask">
                        {contact?.input?.value}
                      </span>
                    </RawCell>
                  )}
                  {contact?.type === "phone" && (
                    <RawCell>
                      <span data-dd-privacy="mask">
                        {contact?.input?.value}
                      </span>
                    </RawCell>
                  )}
                  {contact?.type === "address" && (
                    <RawCell>
                      <span
                        data-dd-privacy="mask"
                        className={"inner-wrap-details"}
                      >
                        <span className={"street_address_1"}>
                          {contact?.input?.street_address_1?.value}
                        </span>
                        <span className={"city"}>
                          {contact?.input?.city?.value + ", "}
                          {contact?.input?.province?.value + " "}
                          {contact?.input?.postal_code?.value}
                        </span>
                        <span className={"country"}>
                          {contact?.input?.country_label?.value}
                        </span>
                      </span>
                    </RawCell>
                  )}
                </BasicRow>
              ))}
            </Table>

            <h2>Bank Accounts</h2>
            <div className="divider" />
            <Table
              className={`primary overview-table ${
                bankAccounts ? "" : "table-no-display-block"
              }`}
            >
              <CellHeader className="table-header">
                <RawCell className="black-bold">Account ID</RawCell>
                <RawCell className="black-bold">Bank and Account #</RawCell>
              </CellHeader>

              {bankAccounts?.map((bank_account: any) => (
                <BasicRow rowClasses={["white-row"]} key={bank_account?.uuid}>
                  <TextCell>{bank_account?.friendly_id}</TextCell>
                  <TextCell>
                    <span className={"data-dog-wrap"} data-dd-privacy="mask">
                      {bank_account?.bank_number} {bank_account?.account_number}
                    </span>
                  </TextCell>
                </BasicRow>
              ))}

              <BasicRow rowClasses={["white-row", "no-bottom-border"]}>
                <TextCell
                  colSpan={2}
                  className={"no-bottom-border contact-us-row no-bold"}
                >
                  To add or remove bank accounts, please contact us at
                  587-430-2985
                </TextCell>
              </BasicRow>
            </Table>

            <div className="footer-content"></div>
          </div>
        </div>
        <div className="footer">
          <div className="footer__phone-link">
            Need help?
            <PhoneLink />
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorAccountDetails;
