/* eslint-disable */
import { AuthService } from "./auth.service";
import { inject } from "inversify"; /* eslint-disable @typescript-eslint/no-unused-vars */
import { SERVICES } from "."; // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { injectable } from "inversify"; // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import {
  Observable,
  switchMap,
  tap,
  map,
  from,
  zip,
  of,
  lastValueFrom,
  Subscription,
} from "rxjs";
import { fromFetch } from "rxjs/fetch";
import { Dispatch } from "react";
import { pushNotification } from "@truenorthmortgage/olympus";

@injectable() // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export class HttpService {
  protected apiUrl: string;

  @inject(SERVICES.AuthService) protected authService: AuthService | null =
    null;

  constructor() {
    this.apiUrl = HttpService.getApiUrl();
  }

  async fetchJson<T = any>(
    path: string,
    method = "GET",
    data: any = null
  ): Promise<T> {
    const [status, body] = await HttpService.executeFetchRequest(
      path,
      this.authService!.getAuthorizationHeaders(
        data instanceof FormData ? { contentType: null } : undefined
      ),
      method,
      data
    );
    this.authService!.validateRequest(status, body);

    return body as T;
  }

  fetchJsonObservable<T = any>(
    path: string,
    method = "GET",
    data: any = null
  ): Observable<T> {
    return HttpService.executeFetchRequestObservable(
      path,
      this.authService!.getAuthorizationHeaders(
        data instanceof FormData ? { contentType: null } : undefined
      ),
      method,
      data
    ).pipe(
      tap(([status, body]) => {
        this.authService!.validateRequest(status, body);
      }),
      map(([_status, body]) => body)
    );
  }

  public static getApiUrl(): string {
    return window.REACT_APP_KRATOS_API_BASE_URL as string;
  }

  public static executeFetchRequestObservable(
    path: string,
    headers: Record<string, string> = {},
    method = "GET",
    data: any = null
  ): Observable<[number, any]> {
    if (path.length > 0 && !path.startsWith("/")) {
      path = "/" + path;
    }

    return fromFetch(`${this.getApiUrl()}${path}`, {
      credentials: "include",
      method,
      body:
        data instanceof FormData
          ? data
          : data
          ? JSON.stringify(data)
          : undefined,
      headers,
    }).pipe(
      switchMap((response) =>
        zip(
          of(response.status),
          response.headers.get("content-type")?.includes("application/json")
            ? from(response.json())
            : from(response.blob())
        )
      ),
      map(([status, body]) => {
        if (body instanceof Blob) {
          body = { __blob_data: URL.createObjectURL(body) };
        }
        if (status === 200 && body && body.status_code !== undefined) {
          if (body.status_code === 200) {
            return [200, body.data ?? body.message];
          } else if (body.message) {
            return [body.status_code, body.message];
          }
        }
        return [status, body];
      })
    );
  }

  public static async executeFetchRequest(
    path: string,
    headers: Record<string, string> = {},
    method = "GET",
    data: any = null
  ): Promise<[number, any]> {
    return lastValueFrom(
      this.executeFetchRequestObservable(path, headers, method, data)
    );
  }

  public static subscribe(
    observable: Observable<any>,
    dispatch: Dispatch<any>,
    next?: (data: any) => void
  ): Subscription {
    return observable.subscribe({
      next,
      error: (e) => {
        dispatch(pushNotification({ class: "error", message: e.toString() }));
      },
    });
  }
}
