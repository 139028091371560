import React from "react";
import { Navigate } from "react-router-dom";
import { utils } from "@truenorthmortgage/olympus";
import { AuthService } from "../../services/auth.service";
import { SERVICES } from "../../services";
import { notifyErrors } from "../../utils";

interface Props {
  component: React.ComponentType;
  path?: string;
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
}) => {
  const authService = utils.injection.useInjection<AuthService>(
    SERVICES.AuthService
  );

  let isAuthenticated = false;

  if (authService?.token) {
    isAuthenticated = true;
  }

  if (isAuthenticated) {
    return <RouteComponent />;
  }

  notifyErrors(["Please login."]);
  return <Navigate to="/" />;
};
