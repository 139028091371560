import { FC } from "react";
import { PHONE } from "../../App";
import transformPhoneNumber from "../../utils/transformPhoneNumber";

interface PhoneLinkInterface {
  className?: string;
}

const PhoneLink: FC<PhoneLinkInterface> = ({ className = "" }) => {
  const telHref = transformPhoneNumber(PHONE);

  return (
    <a href={`tel:${telHref}`} className={className}>
      {PHONE}
    </a>
  );
};

export default PhoneLink;
