import React from "react";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import messages from "./translations";
import {
  IntlProvider,
  InversifyProvider,
  store,
} from "@truenorthmortgage/olympus";
import { Container } from "inversify";

import { SERVICES } from "./services";
import { HttpService } from "./services/http.service";
import { AuthService } from "./services/auth.service";
import { LoginService } from "./services/login-page.service";
import { DashboardPageService } from "./services/dashboard-page.service";
import { InvestmentAccountService } from "./services/investment-account-service";
import { TransactionService } from "./services/transaction-service";
import { ForgetPasswordService } from "./services/forget-password-service";

import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { InvestmentDetailsService } from "./services/investment-details-service";
import { InvestmentListingsService } from "./services/investment-listings-service";
import { ResetPasswordService } from "./services/reset-password-service";
import { InvestorAccountService } from "./services/investor-account-service";
import { AccountActivateService } from "./services/account-activate-service";
import { ActionQueueService } from "./services/action-queue-service";

const container = new Container();

container
  .bind<HttpService>(SERVICES.HttpService)
  .to(HttpService)
  .inSingletonScope();
container
  .bind<DashboardPageService>(SERVICES.DashboardPageService)
  .to(DashboardPageService)
  .inSingletonScope();
container
  .bind<InvestmentAccountService>(SERVICES.InvestmentAccountService)
  .to(InvestmentAccountService)
  .inSingletonScope();
container
  .bind<AuthService>(SERVICES.AuthService)
  .to(AuthService)
  .inSingletonScope();
container
  .bind<TransactionService>(SERVICES.TransactionService)
  .to(TransactionService)
  .inSingletonScope();
container
  .bind<InvestmentDetailsService>(SERVICES.InvestmentDetailService)
  .to(InvestmentDetailsService)
  .inSingletonScope();
container
  .bind<LoginService>(SERVICES.LoginService)
  .to(LoginService)
  .inSingletonScope();
container
  .bind<InvestmentListingsService>(SERVICES.InvestmentListingService)
  .to(InvestmentListingsService)
  .inSingletonScope();
container
  .bind<InvestorAccountService>(SERVICES.InvestorAccountService)
  .to(InvestorAccountService)
  .inSingletonScope();
container
  .bind<ForgetPasswordService>(SERVICES.ForgetPasswordService)
  .to(ForgetPasswordService)
  .inSingletonScope();
container
  .bind<ResetPasswordService>(SERVICES.ResetPasswordService)
  .to(ResetPasswordService)
  .inSingletonScope();
container
  .bind<AccountActivateService>(SERVICES.AccountActivateService)
  .to(AccountActivateService)
  .inSingletonScope();
container
  .bind<ActionQueueService>(SERVICES.ActionQueueService)
  .to(ActionQueueService)
  .inSingletonScope();

// const store = createStore({ session: sessionReducer });

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);
root.render(
  <InversifyProvider container={container}>
    <Provider store={store}>
      <IntlProvider messages={messages}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </IntlProvider>
    </Provider>
  </InversifyProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
