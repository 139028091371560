import { FC, useEffect, useState } from "react";
import { Pagination } from "@truenorthmortgage/olympus";

export type KratosPaginationProps = {
  total_pages?: any;
  page?: any;
  paginationCallback?: any;
};

const KratosPagination: FC<KratosPaginationProps> = ({
  total_pages = null,
  page = null,
  paginationCallback = null,
}) => {
  const [externalPage] = useState(page);
  const [totalPages] = useState(total_pages);
  const [currentPage, setCurrentPage] = useState(Number(page));
  const [currentTotalPage, setCurrentTotalPage] = useState(Number(total_pages));

  const handlePagination = (val: any) => {
    if (val == undefined) {
      return;
    }
    if (externalPage == undefined) {
      return;
    }
    setCurrentPage(Number(val));
  };

  useEffect(() => {
    if (paginationCallback) {
      if (currentPage != 0) {
        paginationCallback(currentPage);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setCurrentTotalPage(total_pages);
  }, [total_pages]);

  useEffect(() => {
    setCurrentPage(Number(externalPage));
  }, [externalPage]);

  useEffect(() => {
    setCurrentTotalPage(Number(totalPages));
  }, [totalPages]);

  return (
    <>
      <Pagination
        key="pagination_key"
        id="investment_pagination"
        className="pagination-main"
        currentPage={currentPage ?? 1}
        totalPaginationItems={currentTotalPage < 7 ? currentTotalPage : 7}
        totalPages={currentTotalPage}
        onChange={handlePagination}
      />
    </>
  );
};

export default KratosPagination;
