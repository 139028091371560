import { useEffect } from "react";
import { useIntl } from "react-intl";
import { LoginLayout } from "../components";

const Logout = () => {
  const intl = useIntl();
  const greeting = intl.formatMessage({ id: "Please Log In" });

  /**
   * Verify the user is logged In
   */
  useEffect(() => {}, []);

  return (
    <LoginLayout greeting={greeting} className="login-card">
      <h1>Logout</h1>
    </LoginLayout>
  );
};

export default Logout;
