import { FC, FormEvent, useCallback, useEffect, useState } from "react";

import {
  Column,
  Container,
  MoneyComponent,
  SelectComponent,
  DateComponent,
  utils,
} from "@truenorthmortgage/olympus";
import {
  formatNumberToMoneyFormat,
  notify,
  notifyErrors,
  unitsDividedByMultiplier,
  unitsMultipliedByMultiplier,
  convertMoneyStringToInteger,
} from "../../../utils";
import { ERROR } from "../../../constants";
import { SERVICES } from "../../../services";
import { ActionQueueService } from "../../../services/action-queue-service";
import {
  ActionQueueCancelForm,
  DefaultResponseRequest,
  FundAccountResponse,
} from "../../../models/schemas/request-schema";
import { ActionQueueFundForm } from "../../../models/schemas/request-schema";
import Loading from "../../Loading";

export type FundingModalProps = {
  callback?: any;
  account_info?: any;
  cancel_request?: any;
  action_queue_funding_object?: any;
};

const FundingModal: FC<FundingModalProps> = ({
  account_info = null,
  callback = null,
  cancel_request = null,
  action_queue_funding_object = null,
}) => {
  const [loading, setLoading] = useState(false);
  const actionQueueService = utils.injection.useInjection<ActionQueueService>(
    SERVICES.ActionQueueService
  );

  const [errors, setErrors] = useState({
    funding_from_bank_account_uuid: false,
    funding_date: false,
    source_of_funds: false,
    amount: false,
  });

  //const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [showSourceOfFunds, setShowSourceOfFunds] = useState(false);
  const [investmentAccountUuid] = useState(account_info?.uuid);
  const [investorAccountUUID] = useState(account_info?.investorAccount?.uuid);
  const [bankAccountUuid, setBankAccountUUID] = useState("");
  const [requestFundingDate, setRequestFundingDate] = useState("");
  const [sourceOfFunds, setSourceOfFunds] = useState("");
  const [actionQueueUUID, setActionQueueUUID] = useState("");

  const sourceOfFundsCallback = (val: any) => {
    setSourceOfFunds(val);
  };

  const dateCallback = (val: any) => {
    if (val) {
      setRequestFundingDate(val);
    }
  };

  const amountCallback = (amount: any) => {
    const convertedAmount = convertMoneyStringToInteger(amount);
    setAmount(convertedAmount ?? "");

    // Show the reason if the amount is greater than 10000 !
    if (convertedAmount >= 10000) {
      setShowSourceOfFunds(true);
    } else {
      setShowSourceOfFunds(false);
    }
  };

  const bankAccountCallback = (val: any) => {
    setBankAccountUUID(val);
  };

  const cleanErrors = () => {
    setErrors({
      funding_from_bank_account_uuid: false,
      funding_date: false,
      source_of_funds: false,
      amount: false,
    });
  };

  // Reset the form !
  const resetForm = () => {
    setAmount(0);
    setBankAccountUUID("select");
    setRequestFundingDate("");
    setSourceOfFunds("");
    cleanErrors();
  };

  const investor_bank_accounts =
    account_info?.investorAccount?.bank_accounts?.map((bank_account: any) => {
      return (
        <option
          id={bank_account.uuid}
          key={bank_account.uuid}
          value={bank_account.uuid}
        >
          {bank_account?.bank_number + " " + bank_account?.account_number}
        </option>
      );
    });

  const submitCancelRequest = useCallback(
    (event: FormEvent) => {
      const form = {
        action_queue_uuid: actionQueueUUID,
      } as ActionQueueCancelForm;

      event.preventDefault();
      setLoading(true);
      actionQueueService
        .cancelActionQueueRequest(form)
        .then((data: DefaultResponseRequest) => {
          if (true === data.success) {
            notify(data.message as string, "success");
            resetForm();
            callback();
          } else {
            if (data.errors) {
              if (data?.errors?.request_processed === true) {
                callback();
              }
            }
            if (data.message) {
              notify(data.message as string, "error");
            }
            setErrors(data.errors);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          notify(ERROR.INTERNAL_SERVER_ERROR.label, "error");
        });
    },
    [actionQueueService, actionQueueUUID]
  );

  const submitFundingRequest = useCallback(
    (event: FormEvent) => {
      const form = {
        fund_account: {
          investor_account_uuid: investorAccountUUID,
          funding_from_bank_account_uuid: bankAccountUuid,
          funding_to_investment_account_uuid: investmentAccountUuid,
          funding_date: requestFundingDate,
          source_of_funds: sourceOfFunds,
          amount: amount ? amount.toString() : 0,
        },
      } as ActionQueueFundForm;

      event.preventDefault();
      setLoading(true);
      actionQueueService
        .sendFundRequest(form)
        .then((data: FundAccountResponse) => {
          if (true === data.success) {
            notify(data.message as string, "success");
            resetForm();
            callback();
          } else {
            if (data.errors) {
              notifyErrors(data.errors);
            }
            if (data.message) {
              notify(data.message as string, "error");
            }
            setErrors(data.errors);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          notify(ERROR.INTERNAL_SERVER_ERROR.label, "error");
        });
    },
    [
      actionQueueService,
      location,
      bankAccountUuid,
      investmentAccountUuid,
      investorAccountUUID,
      requestFundingDate,
      amount,
      sourceOfFunds,
    ]
  );

  // If we are canceling the request set the values to match the object !
  useEffect(() => {
    if (cancel_request === true) {
      setAmount(Number(action_queue_funding_object?.amount));
      setRequestFundingDate(action_queue_funding_object?.funding_date);
      setBankAccountUUID(action_queue_funding_object?.bank_account?.uuid);
      setSourceOfFunds(action_queue_funding_object?.source_of_funds);
      setActionQueueUUID(action_queue_funding_object?.uuid);
    } else {
      resetForm();
    }
  }, [action_queue_funding_object, cancel_request]);

  // Update the form !
  useEffect(() => {
    if (cancel_request === true) {
      setRequestFundingDate(action_queue_funding_object?.funding_date);
    }
  }, [requestFundingDate, cancel_request]);

  useEffect(() => {
    if (cancel_request === true) {
      setAmount(action_queue_funding_object?.amount);
    }
  }, [amount, cancel_request]);

  useEffect(() => {
    setBankAccountUUID(bankAccountUuid);
  }, [bankAccountUuid]);

  return (
    <>
      <Container
        className={"generic-modal-container action-queue-fund-container"}
      >
        <Column columnStyle={"full create-bank-account-form"}>
          <div className="center">
            <h2>Account Funding Request</h2>
          </div>
        </Column>
        <Column columnStyle={"flex-column"}>
          <Column columnStyle="full">
            <Column columnStyle="side-by-side-element no-left-padding">
              <label>Funding Investment Account</label>
              {account_info?.type_label + " " + account_info?.morcado_id}
            </Column>
          </Column>
          <Column columnStyle="full">
            <Column columnStyle="side-by-side-element">
              <label>Cash Balance</label>$
              {formatNumberToMoneyFormat(
                unitsDividedByMultiplier(
                  account_info?.accountingAccount?.balance,
                  100
                )
              )}
            </Column>
          </Column>
        </Column>
        <Column columnStyle={"flex-column"}>
          <Column columnStyle="side-by-side-element funding-money">
            <label>Funding Amount</label>
            <MoneyComponent
              id="transit_number"
              key="transit_number"
              disabled={
                (loading ? true : false) || (cancel_request ? true : false)
              }
              value={amount ? amount?.toString() : ""}
              onChange={amountCallback}
              error={errors?.amount}
            />
          </Column>
          <Column columnStyle="side-by-side-element">
            <SelectComponent
              label="From Bank Account"
              id="from_bank_account"
              key="from_bank_account"
              value={bankAccountUuid}
              disabled={
                (loading ? true : false) || (cancel_request ? true : false)
              }
              onChange={bankAccountCallback}
              error={errors?.funding_from_bank_account_uuid}
            >
              <option value="select">Select</option>
              {investor_bank_accounts}
            </SelectComponent>
          </Column>
        </Column>
        <Column columnStyle={"flex-column"}>
          <Column columnStyle="side-by-side-element no-left-padding-date-component">
            <label>Requested Transaction Date</label>
            <div className={"date-icon-wrap"}>
              <i className="fa fa-calendar"></i>
              <DateComponent
                id="requested_transaction_date"
                key="requested_transaction_date"
                className={"no-left-padding"}
                onChange={dateCallback}
                minDate={
                  cancel_request ? new Date(requestFundingDate) : new Date()
                }
                disabled={
                  (loading ? true : false) || (cancel_request ? true : false)
                }
                defaultValue={requestFundingDate}
                error={errors?.funding_date}
              />
            </div>
          </Column>
          <Column columnStyle={"side-by-side-element"}></Column>
        </Column>
        {showSourceOfFunds === true ? (
          <Column columnStyle={"flex-column no-left-padding"}>
            <Column columnStyle="side-by-side-element no-left-padding source_of_funds">
              <SelectComponent
                label="Source of Funds"
                id="from_bank_account"
                key="from_bank_account"
                value={sourceOfFunds}
                disabled={
                  (loading ? true : false) || (cancel_request ? true : false)
                }
                onChange={sourceOfFundsCallback}
                error={errors?.source_of_funds}
              >
                <option value="select">Select</option>
                <option value="saving">Savings</option>
                <option value="investments">Investments</option>
                <option value="inheritance">Inheritance</option>
                <option value="sale_of_assets">Sale of Assets</option>
                <option value="insurance_payout">Insurance Payout</option>
                <option value="other">Other</option>
              </SelectComponent>
            </Column>
            <Column columnStyle="side-by-side-element">
              <div className={"info-container"}>
                We are required to ask you for the source of these funds and may
                need to follow up with you for more details.
              </div>
            </Column>
          </Column>
        ) : null}

        {cancel_request === true ? (
          <div></div>
        ) : (
          <Column columnStyle={"flex-column no-left-padding"}>
            <div className={"info-container"}>
              All fields are required. We endeavour to process transactions on
              your requested transaction date, but cannot guarantee the
              transaction date. Funding request must be a minimum of $100.00.
            </div>
          </Column>
        )}

        {cancel_request === true ? (
          <Column columnStyle="flex-column small-top-margin no-left-padding no-right-padding">
            <button
              key="cancel_fund_request"
              type="button"
              className="button submit-button no-right-margin"
              style={{ marginRight: "auto" }}
              onClick={callback}
            >
              <i
                style={{ marginRight: "10px" }}
                className="fa-solid fa-chevron-left"
              ></i>
              Back
            </button>

            <button
              key="send_fund_request"
              type="button"
              className="button submit-button no-right-margin button-red"
              style={{ marginLeft: "auto" }}
              onClick={submitCancelRequest}
              disabled={loading ? true : false}
            >
              {loading === true ? (
                <Loading
                  style={{
                    height: 40,
                    marginTop: -30,
                    position: "relative",
                    top: 14,
                  }}
                  color={"white"}
                />
              ) : (
                " Cancel Request"
              )}
            </button>
          </Column>
        ) : (
          <Column columnStyle="flex-column small-top-margin no-left-padding no-right-padding">
            <button
              key="cancel_fund_request"
              type="button"
              className="button submit-button no-right-margin button-red"
              onClick={callback}
              style={{ marginRight: "auto" }}
            >
              Cancel
            </button>

            <button
              key="send_fund_request"
              type="button"
              className="button submit-button no-right-margin button-green"
              style={{ marginLeft: "auto" }}
              onClick={submitFundingRequest}
              disabled={loading ? true : false}
            >
              {loading === true ? (
                <Loading
                  style={{
                    height: 40,
                    marginTop: -30,
                    position: "relative",
                    top: 14,
                  }}
                  color={"white"}
                />
              ) : (
                " Send Request"
              )}
            </button>
          </Column>
        )}
      </Container>
    </>
  );
};

export default FundingModal;
