import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { SERVICES } from "../services";
import { AccountActivateService } from "../services/account-activate-service";
import { AuthService } from "../services/auth.service";
import { LoginService } from "../services/login-page.service";
import { LoginLayout } from "../components";
import {
  FormSetPassword,
  DefaultResponseRequest,
  FormValidateAccountActivationToken,
} from "../models/schemas/request-schema";
import { ERROR } from "../constants";
import { PasswordComponent, utils } from "@truenorthmortgage/olympus";
import { FormEvent, useCallback, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { notify, notifyErrors } from "../utils";
import Loading from "../components/Loading";

const AccountActivate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const intl = useIntl();
  const [greeting, setGreeting] = useState(
    intl.formatMessage({ id: "Set Your Account Password" })
  );
  const [is_token_valid, setIsTokenValid] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    password: false,
    confirm_password: false,
  });
  const [loading, setLoading] = useState(false);
  const [is_page_loading, setIsPageLoading] = useState(true);
  const accountActivateService =
    utils.injection.useInjection<AccountActivateService>(
      SERVICES.AccountActivateService
    );
  const authService = utils.injection.useInjection<AuthService>(
    SERVICES.AuthService
  );
  const loginService = utils.injection.useInjection<LoginService>(
    SERVICES.LoginService
  );

  useEffect(() => {
    const form_account_activation_token = {
      account_activation_token: token,
    } as FormValidateAccountActivationToken;
    accountActivateService
      .validateAccountActivationToken(form_account_activation_token)
      .then((response: DefaultResponseRequest) => {
        if (true === response.success) {
          if (response.message) {
            notify(response.message as string);
          }
          setIsTokenValid(true);
        } else {
          if (response.errors) {
            notifyErrors(response.errors);
          }
          if (response.message) {
            notify(response.message as string, "error");
          }
          setIsTokenValid(false);
          setGreeting(intl.formatMessage({ id: "Expired Link" }));
        }
        setIsPageLoading(false);
      })
      .catch(() => {
        setIsTokenValid(false);
        notify(ERROR.INTERNAL_SERVER_ERROR.label, "error");
        setIsPageLoading(false);
        setGreeting(intl.formatMessage({ id: "Expired Link" }));
      });
  }, []);

  const submitReset = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      const form = {
        password: password,
        confirm_password: confirm_password,
        account_activation_token: token,
      } as FormSetPassword;
      setLoading(true);
      accountActivateService
        .setPassword(form)
        .then((response: DefaultResponseRequest) => {
          setLoading(false);
          if (true === response.success) {
            if (response.message) {
              notify(response.message as string);
            }
            setErrors(response.errors);
            // Log the user in and redirect to dashboard
            loginAndRedirect(response?.data?.email ?? null);
          } else {
            if (response.errors) {
              notifyErrors(response.errors);
            }
            if (response.message) {
              notify(response.message as string, "error");
            }
            setErrors(response.errors);
          }
        })
        .catch(() => {
          setLoading(false);
          notify(ERROR.INTERNAL_SERVER_ERROR.label, "error");
        });
    },
    [password, confirm_password]
  );

  const passwordCallback = (val: string) => {
    setPassword(val);
  };

  const confirmPasswordCallback = (val: string) => {
    setConfirmPassword(val);
  };

  const loginAndRedirect = (email: null | string) => {
    if (null === email) {
      navigate("/login");
      return;
    }
    const submitValues = {
      email: email,
      password: password ?? null,
      remember_me: null,
    };
    loginService
      .getToken({ LoginForm: submitValues })
      .then((data) => {
        authService.logIn(data.jwt, dispatch);
        navigate("/dashboard");
        setIsPageLoading(false);
      })
      .catch(() => {
        notify(ERROR.INTERNAL_SERVER_ERROR.label, "error");
        navigate("/login");
        setIsPageLoading(false);
      });
  };

  return true === is_page_loading ? (
    <Loading />
  ) : (
    <LoginLayout greeting={greeting} className="login-card form-password">
      <div className="inner-wrap">
        <h4 className="form-password-subheader" style={{ textAlign: "center" }}>
          {true === is_token_valid &&
            "Please choose a strong password to activate your account."}
          {false === is_token_valid &&
            "The link you are trying to access has expired."}
        </h4>
      </div>
      {true === is_token_valid && (
        <form className="form-password-form">
          <PasswordComponent
            value={password}
            placeholder="Password"
            onChange={passwordCallback}
            error={errors?.password}
          />
          <PasswordComponent
            value={confirm_password}
            placeholder="Confirm Password"
            onChange={confirmPasswordCallback}
            error={errors?.confirm_password}
          />
          <ul style={{ listStyle: "disc", paddingLeft: 25 }}>
            <li>At least 8 characters</li>
            <li>At least one letter</li>
            <li>At least one number</li>
          </ul>

          <button
            type="button"
            style={{ height: 40 }}
            disabled={loading}
            className="button button primary"
            onClick={submitReset}
          >
            {loading === true ? (
              <Loading style={{ height: 40, marginTop: -10 }} color={"white"} />
            ) : (
              "Log In"
            )}
          </button>
        </form>
      )}
    </LoginLayout>
  );
};

export default AccountActivate;
