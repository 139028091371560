import { useIntl } from "react-intl";

import { LoginLayout } from "../components";
import { Link } from "react-router-dom";

const NotFound = () => {
  const intl = useIntl();

  const greeting = intl.formatMessage({ id: "Not Found" });

  return (
    <LoginLayout
      greeting={greeting}
      className="four-zero-four-not-found login-card"
    >
      <div className="inner-wrap">
        <h3 style={{ textAlign: "center" }}>
          Sorry, we could not find what you are looking for.
        </h3>
        <Link to="/dashboard">Back to Home</Link>
      </div>
    </LoginLayout>
  );
};

export default NotFound;
