import {
  DefaultResponseRequest,
  FundAccountResponse,
  InvestmentAccountActionQueueRequests,
  WithdrawAccountResponse,
} from "../models/schemas/request-schema";
import { HttpService } from "./http.service";

export class ActionQueueService extends HttpService {
  async sendFundRequest(
    fundAccountRequestData: Record<string, any>
  ): Promise<FundAccountResponse> {
    return await this.fetchJson<FundAccountResponse>(
      "/action-queue/fund",
      "POST",
      fundAccountRequestData
    );
  }

  // Get the action queue items !
  async getInvestmentAccountActionQueueItems(
    InvestmentAccountActionQueueRequests: Record<string, any>
  ): Promise<InvestmentAccountActionQueueRequests> {
    return await this.fetchJson<InvestmentAccountActionQueueRequests>(
      "/action-queue/investment-account-requests",
      "POST",
      InvestmentAccountActionQueueRequests
    );
  }

  // Withdraw Request !
  async sendWithdrawRequest(
    withdrawAccountRequestData: Record<string, any>
  ): Promise<WithdrawAccountResponse> {
    return await this.fetchJson<WithdrawAccountResponse>(
      "/action-queue/withdraw",
      "POST",
      withdrawAccountRequestData
    );
  }

  // Cancel the action queue request !
  async sendPurchaseRequest(
    PurchaseActionQueueRequest: Record<string, any>
  ): Promise<DefaultResponseRequest> {
    return await this.fetchJson<DefaultResponseRequest>(
      "/action-queue/purchase",
      "POST",
      PurchaseActionQueueRequest
    );
  }

  // Cancel the action queue request !
  async cancelActionQueueRequest(
    CancelActionQueueRequest: Record<string, any>
  ): Promise<DefaultResponseRequest> {
    return await this.fetchJson<DefaultResponseRequest>(
      "/action-queue/cancel-request",
      "POST",
      CancelActionQueueRequest
    );
  }
}
