import { FC, useState, useEffect, useRef, ReactNode } from "react";

import {
  BasicModalHandle,
  BasicModal,
  Container,
} from "@truenorthmortgage/olympus";

import "./Modals.scss";

export type KratosModalProps = {
  is_open?: any;
  callback?: any;
  children?: ReactNode;
};

const KratosModal: FC<KratosModalProps> = ({
  is_open = false,
  callback = null,
  children,
}) => {
  const modalRef = useRef<BasicModalHandle>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (is_open === true) {
      modalRef.current?.open();
      if (!isModalOpen) {
        setIsModalOpen(true);
      }
    } else {
      modalRef.current?.close();
      setIsModalOpen(false);
    }
  }, [is_open]);

  return (
    <>
      {/* Show Hide the Different Modals Here Based off the State or Create New Components */}
      <div style={{ position: "absolute" }}>
        <Container className={"general-modal-container"}>
          <BasicModal
            ref={modalRef}
            title="Generic Modal"
            onClose={callback}
            className={"general-modal"}
          >
            {children}
          </BasicModal>
        </Container>
      </div>
    </>
  );
};

export default KratosModal;
