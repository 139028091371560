import { HttpService } from "./http.service";

export class InvestmentAccountService extends HttpService {
  async getInvestmentAccountData(
    investmentAccountData: Record<string, any>
  ): Promise<{
    investment_account: any;
    total_invested: any;
    total_investments: any;
    ownership_certificates: any;
  }> {
    return await this.fetchJson<{
      investment_account: any;
      total_invested: any;
      total_investments: any;
      ownership_certificates: any;
    }>("/user/investment-account", "POST", investmentAccountData);
  }

  // Get all the investment accounts for the portal user  !
  async getInvestmentAccounts(): Promise<{
    investment_accounts: any;
  }> {
    return await this.fetchJson<{
      investment_accounts: any;
    }>("/action-queue/portal-user-investment-accounts", "GET");
  }
}
