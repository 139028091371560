import "./scss/App.scss";
import "reflect-metadata";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { utils } from "@truenorthmortgage/olympus";
import { datadogRum } from "@datadog/browser-rum";
import Login from "./pages/Login";
import Layout from "./Layout";
import Logout from "./pages/Logout";
import Dashboard from "./pages/Dashboard";
import InvestmentAccount from "./pages/InvestmentAccount";
import { PrivateRoute } from "./components/Routes/ProtectedRoute";
import NotFound from "./pages/404";
import ForgotPassword from "./pages/ForgotPassword";
import InvestmentOwnershipDetails from "./pages/InvestmentOwnershipDetails";
import InvestmentListings from "./pages/InvestmentListings";
import ResetPassword from "./pages/ResetPassword";
import AccountActivate from "./pages/AccountActivate";

export const PHONE = "587-430-2985";

import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import InvestorAccountDetails from "./pages/InvestorAccountDetails";
library.add(far, fas);

/* eslint-disable */
declare global {
  interface Window {
    REACT_APP_ENVIRONMENT: string;
    REACT_APP_DATADOG_RUM_APPLICATION_ID: string;
    REACT_APP_DATADOG_RUM_CLIENT_TOKEN: string;
    REACT_APP_PORTAL_DOMAIN: string;
    REACT_APP_DATADOG_SAMPLE_RATE: number;
    REACT_APP_DATADOG_REPLAY_RATE: number;
    REACT_APP_KRATOS_API_BASE_URL: string;
    REACT_APP_GOOGLE_MAPS_JS_API_KEY: string;
    REACT_APP_VERSION: string;
  }
}
/* eslint-enable */

datadogRum.init({
  applicationId: window.REACT_APP_DATADOG_RUM_APPLICATION_ID,
  clientToken: window.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: "investor-portal",
  env: window.REACT_APP_ENVIRONMENT,
  version: window.REACT_APP_VERSION,
  sessionSampleRate: window.REACT_APP_DATADOG_SAMPLE_RATE,
  sessionReplaySampleRate: window.REACT_APP_DATADOG_REPLAY_RATE,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();

utils.font.setFontAwesome();

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Layout />}>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/account/forgot-password" element={<Layout />}>
          <Route path="/account/forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route path="/account/reset-password" element={<Layout />}>
          <Route
            path="/account/reset-password/:token"
            element={<ResetPassword />}
          />
        </Route>
        <Route path="/account/activate" element={<Layout />}>
          <Route
            path="/account/activate/:token"
            element={<AccountActivate />}
          />
        </Route>
        <Route
          path="/dashboard"
          element={<PrivateRoute component={Dashboard} />}
        ></Route>
        <Route
          path="/investment-account/:investment_account_uuid"
          element={<PrivateRoute component={InvestmentAccount} />}
        ></Route>
        <Route
          path="/investment-listings"
          element={<PrivateRoute component={InvestmentListings} />}
        ></Route>
        <Route
          path="/investor-account-details/:investor_account_uuid"
          element={<PrivateRoute component={InvestorAccountDetails} />}
        ></Route>
        <Route
          path="/investment/:investment_uuid"
          element={<PrivateRoute component={InvestmentOwnershipDetails} />}
        ></Route>
        <Route path="/logout" element={<Layout />}>
          <Route path="" element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
