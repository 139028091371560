import { useState, useCallback, FormEvent, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { SERVICES } from "../services";
import { AuthService } from "../services/auth.service";
import { LoginService } from "../services/login-page.service";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Loading from "../components/Loading";
import { ERROR } from "../constants";
import { notify, notifyErrors } from "../utils";

import {
  TextComponent,
  CheckboxOption,
  PasswordComponent,
  CheckboxComponent,
  utils,
} from "@truenorthmortgage/olympus";
import { JwtPayload } from "../models/schemas/request-schema";
import { LoginLayout } from "../components";

const Login = () => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  /* eslint-disable */
  const [loggedIn, setLoggedIn] = useState(false);
  /* eslint-enable */
  const [loading, setLoading] = useState(false);
  const greeting = intl.formatMessage({ id: "Please Log In" });
  const emailPlaceholder = intl.formatMessage({ id: "Email" });
  const passwordPlaceholder = intl.formatMessage({ id: "Password" });
  const rememberMeMsg = intl.formatMessage({ id: "Remember Me" });
  const [errors, setErrors] = useState({ email: false, password: false });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [rememberMeStorage] = useState(localStorage.getItem("remember_me"));

  const loginService = utils.injection.useInjection<LoginService>(
    SERVICES.LoginService
  );

  const authService = utils.injection.useInjection<AuthService>(
    SERVICES.AuthService
  );

  // Check the local storage fora  remember me cookie !
  useEffect(() => {
    if (rememberMeStorage) {
      setEmail(rememberMeStorage);
      setRememberMe(true);
    }
  }, [rememberMeStorage]);

  // logins the user
  const submitLogin = useCallback(
    (event: FormEvent) => {
      const submitValues = {
        email: email ?? null,
        password: password ?? null,
        remember_me: rememberMe ?? null,
      };
      event.preventDefault();

      setLoading(true);
      loginService
        .getToken({ LoginForm: submitValues })
        .then((data) => {
          if (true === data.success) {
            const decodedToken = jwtDecode<JwtPayload>(data.jwt);
            if (decodedToken && decodedToken.exp) {
              const current_time = Date.now() / 1000;

              // IF the token is expired log the user out !
              if (decodedToken?.exp < current_time) {
                authService.logOut(navigate);
              } else {
                // Log the user in if the token is valid
                authService.logIn(data.jwt, dispatch);

                // If the token has the remember_me store it otherwise remove it
                if (decodedToken?.remember_me === true) {
                  localStorage.setItem("remember_me", decodedToken?.email);
                  setEmail(decodedToken?.email);
                  setRememberMe(true);
                } else {
                  localStorage.removeItem("remember_me");
                }
              }
            }

            setLoggedIn(true);
            setLoading(false);
            navigate("/dashboard");
            setLoading(false);
          } else {
            if (data.errors) {
              notifyErrors(data.errors);
            }
            if (data.message) {
              notify(data.message as string, "error");
            }
            setErrors(data.errors);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
          notify(ERROR.INTERNAL_SERVER_ERROR.label, "error");
        });
    },
    [loginService, location, authService, email, password, rememberMe]
  );

  function emailCallback(val: any) {
    setEmail(val);
  }

  function passwordCallback(val: any) {
    setPassword(val);
  }

  function rememberMeCallback(val: any) {
    if (val === "0") {
      setRememberMe(false);
    } else {
      setRememberMe(true);
    }
  }

  // Watch for enter key and submit the form !
  useEffect(() => {
    const handleEnter = (event: any) => {
      if (event.key === "Enter") {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }
    };
    window.addEventListener("keydown", handleEnter);

    return () => {
      window.removeEventListener("keydown", handleEnter);
    };
  }, []);

  return (
    <LoginLayout greeting={greeting} className="login-card">
      <form>
        <TextComponent
          data-dd-privacy="mask"
          formData={email}
          placeholder={emailPlaceholder}
          onChange={emailCallback}
          error={errors?.email}
        />

        <PasswordComponent
          value={password}
          placeholder={passwordPlaceholder}
          onChange={passwordCallback}
          error={errors?.password}
        />

        <CheckboxComponent
          name="rememberMe"
          value={rememberMe}
          onChange={rememberMeCallback}
        >
          <CheckboxOption label={rememberMeMsg} value="true" />
        </CheckboxComponent>

        <button
          type="button"
          ref={buttonRef}
          style={{ height: 40 }}
          disabled={loading}
          className="button button primary"
          onClick={submitLogin}
        >
          {loading === true ? (
            <Loading style={{ height: 40, marginTop: -10 }} color={"white"} />
          ) : (
            "Log In"
          )}
        </button>
      </form>
    </LoginLayout>
  );
};

export default Login;
