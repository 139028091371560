import { HttpService } from "./http.service";

export class InvestorAccountService extends HttpService {
  async getInvestorAccount(investorAccountForm: Record<string, any>): Promise<{
    investor_account: any;
  }> {
    return await this.fetchJson<{
      investor_account: any;
    }>("/user/investor-account", "POST", investorAccountForm);
  }
}
