import { LoginResponseRequest } from "../models/schemas/request-schema";
import { HttpService } from "./http.service";

export class LoginService extends HttpService {
  async getToken(
    loginData: Record<string, any>
  ): Promise<LoginResponseRequest> {
    return await this.fetchJson<LoginResponseRequest>(
      "/user/authenticate",
      "POST",
      loginData
    );
  }
}
