import { useMemo, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { SERVICES } from "../services";
import { InvestmentAccountService } from "../services/investment-account-service";
import { useNavigate, useParams } from "react-router-dom";
import { formatNumberToMoneyFormat } from "../utils";
import Transactions from "../components/Transactions";
import ProgressBar from "../components/ProgressBar";
import KratosModal from "../components/Modals/KratosModal";
import FundingModal from "../components/Modals/ActionQueue/FundingModal";
import WithdrawModal from "../components/Modals/ActionQueue/WithdrawModal";
import {
  unitsDividedByMultiplier,
  getPercent,
  calcInvestorRate,
  getFormattedDate,
  convertFromUTCtoLocalTime,
  formatDateFromString,
} from "../utils";

import {
  utils,
  ActionCards,
  ActionCard,
  Table,
  BasicRow,
  TextCell,
  CellHeader,
  RawCell,
  ActionButton,
  ActionButtonsCell,
  Notifications,
} from "@truenorthmortgage/olympus";
import Loading from "../components/Loading";
import NavComponent from "../components/NavComponent";
import NotificationComponent from "../components/NotificationComponent";
import BreadcrumbsComponent from "../components/BreadcrumbsComponent";
import { PhoneLink } from "../components";
import { InvestmentAccountActionQueueRequestsForm } from "../models/schemas/request-schema";
import { ActionQueueService } from "../services/action-queue-service";
import NotAllowedToWithdrawModal from "../components/Modals/ActionQueue/NotAllowedToWithdrawModal";
import PurchaseModal from "../components/Modals/ActionQueue/PurchaseModal";

const InvestmentAccount = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const optionLabel = intl.formatMessage({ id: "Options" });

  const [userName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const investmentAccountService =
    utils.injection.useInjection<InvestmentAccountService>(
      SERVICES.InvestmentAccountService
    );

  const actionQueueService = utils.injection.useInjection<ActionQueueService>(
    SERVICES.ActionQueueService
  );

  // Set the states for dahsboard data
  const [cashBalance, setCashBalance] = useState(0);
  const [totalInvested, setTotalInvested] = useState(0);
  const [totalInvestments, setTotalInvestments] = useState(0);
  const [investmentFriendlyId, setInvestmentFriendlyId] = useState("");
  const [investmentMorcadoId, setInvestmentMorcadoId] = useState("");
  /* eslint-disable */
  const [ownerShipRecords, setOwnershipRecords] = useState([]);
  /* eslint-enable */
  const [transactions, setTransactions] = useState([]);
  const [ownershipCertificates, setOwnershipCertificates] = useState([]);
  const [investmentAccount, setInvestmentAccount] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountNickname, setAccountNickname] = useState("");
  const [investmentData, setData] = useState(null) as any;
  /* eslint-disable */
  const [
    investmentAccountActionQueueRequests,
    setInvestmentAccountActionQueueRequests,
  ] = useState([]) as any;
  /* eslint-enable */

  // Grab the param from the route !
  const { investment_account_uuid } = useParams();

  // Grab the Dashboard Data !
  // Link this data to values for display !
  useEffect(() => {
    const submitValues = {
      uuid: investment_account_uuid ?? null,
    };
    investmentAccountService
      .getInvestmentAccountData({ InvestmentAccountForm: submitValues })
      .then((data) => {
        setData(data?.investment_account);
        setInvestmentMorcadoId(data?.investment_account?.morcado_id);
        setInvestmentFriendlyId(data?.investment_account?.friendly_id);
        setTotalInvested(data?.total_invested);
        setCashBalance(data?.investment_account?.accountingAccount?.balance);
        setTotalInvestments(data?.total_investments);
        setOwnershipRecords(data?.investment_account?.ownershipCertificates);
        setTransactions(data?.investment_account?.transactions);
        setOwnershipCertificates(data?.ownership_certificates);
        setInvestmentAccount(data?.investment_account?.uuid);
        setAccountType(data?.investment_account?.type_label);
        setAccountNickname(data?.investment_account?.user_nickname);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [userName]);

  // Investor account => investorData?.investorAccount?.uuid
  useEffect(() => {
    const form = {
      investment_account_uuid: investmentData?.uuid,
    } as InvestmentAccountActionQueueRequestsForm;

    actionQueueService
      .getInvestmentAccountActionQueueItems({
        InvestmentAccountActionQueueRequestsForm: form,
      })
      .then((data) => {
        setInvestmentAccountActionQueueRequests(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [investmentData]);

  // Open Fund Account Modal callback !
  const [isActionQueuePurchaseRejectOpen, setIsActionQueuePurchaseRejectOpen] =
    useState(false);

  // Open the cancel model !
  const [
    isActionQueuePurchaseRejectCancelOpen,
    setIsActionQueuePurchaseRejectCancelOpen,
  ] = useState(false);

  // Open Fund Account Modal !
  const openFundAccountModal = () => {
    setIsActionQueuePurchaseRejectOpen(true);
  };

  const closeFundAccountModal = () => {
    setIsActionQueuePurchaseRejectOpen(false);
  };

  const closeFundAccountModalCancel = () => {
    setIsActionQueuePurchaseRejectCancelOpen(false);
  };

  const [actionQueueFundingObject, setActionQueueFundingObject] = useState({});

  const [actionQueueFundingCancel, setActionQueueFundingCancel] =
    useState(false);

  const [actionQueueWithdrawalCancel, setActionQueueWithdrawalCancel] =
    useState(false);

  const findActionQueueFundingObject = (uuid: string) => {
    return investmentAccountActionQueueRequests?.find(
      (action_queue_item: any) => action_queue_item.uuid === uuid
    );
  };

  const openCancelFunRequestModal = (val: any) => {
    setActionQueueFundingCancel(true);
    setActionQueueFundingObject(findActionQueueFundingObject(val));
    setIsActionQueuePurchaseRejectCancelOpen(true);
  };

  // Open Withdraw Account Modal callback !
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

  const openWithDrawAccountModal = () => {
    setIsWithdrawCancelModalOpen(false);
    setActionQueueWithdrawalCancel(false);
    if (investmentData?.type === "tfsa") {
      setIsWithdrawModalOpen(true);
    } else if (investmentData?.type === "cash") {
      setIsWithdrawModalOpen(true);
    } else {
      openIsNotAllowedToWithdraw();
    }
  };

  const closeWithdrawAccountModal = () => {
    setIsWithdrawModalOpen(false);
  };

  // Open Withdraw Cancel Modal !
  const [isWithdrawCancelModalOpen, setIsWithdrawCancelModalOpen] =
    useState(false);

  const openWithdrawCancelModalOpen = (val: any) => {
    setActionQueueFundingObject(findActionQueueFundingObject(val));
    setIsWithdrawCancelModalOpen(true);
    setActionQueueWithdrawalCancel(true);
  };
  const closeWithdrawCancelModalOpen = () => {
    setIsWithdrawCancelModalOpen(false);
  };

  // Open Purchase Cancel Modal !
  const [isPurchaseCancelModalOpen, setIsPurchaseCancelModalOpen] =
    useState(false);

  const openPurchaseCancelModalOpen = (val: any) => {
    setActionQueueFundingObject(findActionQueueFundingObject(val));
    setIsPurchaseCancelModalOpen(true);
    setActionQueueWithdrawalCancel(true);
  };

  const closePurchaseCancelModalOpen = () => {
    setIsPurchaseCancelModalOpen(false);
  };

  // Not allowed to withdraw modal
  const [isNotAllowedToWithdraw, setIsNotAllowedToWithdraw] = useState(false);

  const openIsNotAllowedToWithdraw = () => {
    setIsNotAllowedToWithdraw(true);
  };
  const closeIsNotAllowedToWithdraw = () => {
    setIsNotAllowedToWithdraw(false);
  };

  const investmentListingsLabel = intl.formatMessage({
    id: "Investment Listings",
  });

  const addFundsLabel = intl.formatMessage({
    id: "Add Funds",
  });

  const withdrawFundsLabel = intl.formatMessage({
    id: "Withdraw Funds",
  });

  const cards = useMemo(
    () => [
      {
        label: investmentListingsLabel,
        icon: "file-alt",
        path: "/investment-listings",
      },
      {
        label: addFundsLabel,
        icon: "plus",
        modalFunction: openFundAccountModal,
      },
      {
        label: withdrawFundsLabel,
        icon: "minus",
        modalFunction: openWithDrawAccountModal,
      },
    ],
    [investmentListingsLabel, accountType]
  );

  const breadcrumbs = [
    { label: "Dashboard", path: "/dashboard" },
    {
      label: accountType + (accountNickname ? "-" + accountNickname : ""),
      path: "/investment-account/" + investment_account_uuid,
    },
  ];

  const investmentDetails = (val: any) => {
    navigate(
      "/investment/" +
        val +
        "?investment_account_uuid=" +
        investment_account_uuid
    );
  };

  // Track when a modal is closed to update the requests Cancel Requests!
  useEffect(() => {
    const form = {
      investment_account_uuid: investmentData?.uuid,
    } as InvestmentAccountActionQueueRequestsForm;

    actionQueueService
      .getInvestmentAccountActionQueueItems({
        InvestmentAccountActionQueueRequestsForm: form,
      })
      .then((data) => {
        setInvestmentAccountActionQueueRequests(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [
    isActionQueuePurchaseRejectCancelOpen,
    isWithdrawCancelModalOpen,
    isPurchaseCancelModalOpen,
  ]);

  // Fund Request !
  useEffect(() => {
    const form = {
      investment_account_uuid: investmentData?.uuid,
    } as InvestmentAccountActionQueueRequestsForm;

    actionQueueService
      .getInvestmentAccountActionQueueItems({
        InvestmentAccountActionQueueRequestsForm: form,
      })
      .then((data) => {
        setInvestmentAccountActionQueueRequests(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [isActionQueuePurchaseRejectOpen, isWithdrawModalOpen]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {/* Modal Sections */}

      {/* Fund To Account */}
      <KratosModal
        is_open={isActionQueuePurchaseRejectOpen}
        callback={closeFundAccountModal}
      >
        <FundingModal
          account_info={investmentData}
          callback={closeFundAccountModal}
          cancel_request={false}
        />
      </KratosModal>

      {/* Cancel Funding Account*/}
      <KratosModal
        is_open={isActionQueuePurchaseRejectCancelOpen}
        callback={closeFundAccountModalCancel}
      >
        <FundingModal
          account_info={investmentData}
          callback={closeFundAccountModalCancel}
          cancel_request={actionQueueFundingCancel}
          action_queue_funding_object={actionQueueFundingObject}
        />
      </KratosModal>

      {/* Withdraw From Account */}
      <KratosModal
        is_open={isWithdrawModalOpen}
        callback={closeWithdrawAccountModal}
      >
        <WithdrawModal
          account_info={investmentData}
          callback={closeWithdrawAccountModal}
          cancel_request={actionQueueWithdrawalCancel}
          action_queue_withdraw_object={actionQueueFundingObject}
        />
      </KratosModal>

      {/* Cancel Withdrawal Account*/}
      <KratosModal
        is_open={isWithdrawCancelModalOpen}
        callback={closeWithdrawCancelModalOpen}
      >
        <WithdrawModal
          account_info={investmentData}
          callback={closeWithdrawCancelModalOpen}
          cancel_request={actionQueueWithdrawalCancel}
          action_queue_withdraw_object={actionQueueFundingObject}
        />
      </KratosModal>

      {/* Cancel Withdrawal Account*/}
      <KratosModal
        is_open={isNotAllowedToWithdraw}
        callback={closeIsNotAllowedToWithdraw}
      >
        <NotAllowedToWithdrawModal callback={closeIsNotAllowedToWithdraw} />
      </KratosModal>

      {/* Cancel Purchase*/}
      <KratosModal
        is_open={isPurchaseCancelModalOpen}
        callback={closePurchaseCancelModalOpen}
      >
        <PurchaseModal
          account_info={investmentData}
          callback={closePurchaseCancelModalOpen}
          cancel_request={actionQueueWithdrawalCancel}
          action_queue_purchase_object={actionQueueFundingObject}
        />
      </KratosModal>

      <div className="dashboard-container">
        <NavComponent />
      </div>
      <div id="main" className="dashboard-content">
        <Notifications
          timeout={15000}
          customNotificationElement={<NotificationComponent />}
        />
        <div className="main-header">
          <BreadcrumbsComponent items={breadcrumbs} />s
        </div>
        <div className="main-content">
          <div className="dashboard">
            <h1
              style={{ textTransform: "capitalize", marginBottom: "3px" }}
              className={"mobile-header-investment-account"}
            >
              {accountType}{" "}
              <span data-dd-privacy="mask">
                {accountNickname ? "- " + accountNickname : ""}
              </span>
            </h1>
            <h3>
              Account ID{" "}
              <span data-dd-privacy="mask">{investmentMorcadoId}</span>
            </h3>

            <h2>{optionLabel}</h2>
            <div className="divider" />
            <ActionCards>
              {cards.map(({ label, icon, path, modalFunction }) => (
                <ActionCard key={icon} path={path} onClick={modalFunction}>
                  <i className={"fa fa-" + icon}></i>
                  <br />
                  <span>{label}</span>
                </ActionCard>
              ))}
            </ActionCards>

            <h2>Overview</h2>
            <div className="divider" />
            <Table className="primary overview-table table-no-display-block">
              <BasicRow>
                <TextCell>Total Invested</TextCell>
                <TextCell>
                  <span className={"data-dog-wrap"} data-dd-privacy="mask">
                    ${formatNumberToMoneyFormat(totalInvested)}
                  </span>
                </TextCell>
              </BasicRow>
              <BasicRow>
                <TextCell>Total Investments</TextCell>
                <TextCell>
                  <span className={"data-dog-wrap"} data-dd-privacy="mask">
                    {totalInvestments}
                  </span>
                </TextCell>
              </BasicRow>
              <BasicRow>
                <TextCell>Cash Balance</TextCell>
                <TextCell>
                  <span className={"data-dog-wrap"} data-dd-privacy="mask">
                    $
                    {formatNumberToMoneyFormat(
                      unitsDividedByMultiplier(cashBalance, 100)
                    )}
                  </span>
                </TextCell>
              </BasicRow>
            </Table>

            <h2>Pending Requests</h2>
            <div className="divider" />
            <Table
              className="primary overview-table pending-action-queue-requests table-no-display-block"
              isEmpty={investmentAccountActionQueueRequests?.length < 1}
            >
              <CellHeader className="table-header">
                <RawCell className="black-bold">Requested</RawCell>
                <RawCell className="black-bold">Transaction Type/Date</RawCell>
                <RawCell className="black-bold">Details</RawCell>
                <RawCell></RawCell>
              </CellHeader>

              {investmentAccountActionQueueRequests?.map((record: any) => (
                <BasicRow className="table-rows" key="action_queue_requests">
                  {record?.action_type === "funding" ? (
                    <>
                      <TextCell className="white-row">
                        {getFormattedDate(
                          convertFromUTCtoLocalTime(record?.requested_at)
                        )}
                      </TextCell>
                      <TextCell className="white-row">
                        Account Funding
                        <span>
                          {"Transaction Date: " + record?.funding_date}
                        </span>
                      </TextCell>
                      <RawCell className="white-row">
                        <div className={"request-details"}>
                          <p>${formatNumberToMoneyFormat(record?.amount)}</p>
                          <p
                            className={"grey-text"}
                            style={{ marginTop: "5px" }}
                          >
                            From bank account
                          </p>
                          <p className={"grey-text"}>
                            {record?.bank_account?.bank_number}{" "}
                            {record?.bank_account?.account_number}
                          </p>
                        </div>
                      </RawCell>
                      <ActionButtonsCell className="white-row">
                        <ActionButton
                          className={"red margin-left-auto"}
                          id={"cancel-action-queue-request-" + record?.uuid}
                          key={"cancel-action-queue-request-" + record?.uuid}
                          callback={() =>
                            openCancelFunRequestModal(record?.uuid)
                          }
                        >
                          <i
                            style={{ marginRight: "5px" }}
                            className="fa-solid fa-xmark"
                          ></i>
                          Cancel
                        </ActionButton>
                      </ActionButtonsCell>
                    </>
                  ) : record?.action_type === "withdrawal" ? (
                    // If action_type is "another_type"
                    <>
                      <TextCell className="white-row">
                        {getFormattedDate(
                          convertFromUTCtoLocalTime(record?.requested_at)
                        )}
                      </TextCell>
                      <TextCell className="white-row">
                        Account Withdrawal
                        <span>
                          {"Transaction Date: " + record?.transaction_date}
                        </span>
                      </TextCell>
                      <RawCell className="white-row">
                        <div className={"request-details"}>
                          <p className={"red-text"}>
                            (${formatNumberToMoneyFormat(record?.amount)})
                          </p>
                          <p
                            className={"grey-text"}
                            style={{ marginTop: "5px" }}
                          >
                            To bank account
                          </p>
                          <p className={"grey-text"}>
                            {record?.bank_account?.bank_number}{" "}
                            {record?.bank_account?.account_number}
                          </p>
                        </div>
                      </RawCell>
                      <ActionButtonsCell className="white-row">
                        <ActionButton
                          className={"red margin-left-auto"}
                          id={"cancel-action-queue-request-" + record?.uuid}
                          key={"cancel-action-queue-request-" + record?.uuid}
                          callback={() =>
                            openWithdrawCancelModalOpen(record?.uuid)
                          }
                        >
                          <i
                            style={{ marginRight: "5px" }}
                            className="fa-solid fa-xmark"
                          ></i>
                          Cancel
                        </ActionButton>
                      </ActionButtonsCell>
                    </>
                  ) : record?.action_type === "purchase" ? (
                    // If action_type is "another_type"
                    <>
                      <TextCell className="white-row">
                        {getFormattedDate(
                          convertFromUTCtoLocalTime(record?.requested_at)
                        )}
                      </TextCell>
                      <TextCell className="white-row">
                        Investment Purchase
                        <span>
                          {"Transaction Date: " + record?.transaction_date}
                        </span>
                      </TextCell>
                      <RawCell className="white-row">
                        <div className={"request-details"}>
                          <p>${formatNumberToMoneyFormat(record?.amount)}</p>
                          <p
                            className={"grey-text"}
                            style={{ marginTop: "5px" }}
                          >
                            Investment Listing{" "}
                            <a
                              href={
                                "/investment-listings/?investment_listing=" +
                                record?.investment_listing?.uuid
                              }
                            >
                              {record?.investment_listing?.friendly_id}
                            </a>
                          </p>
                          <p className={"grey-text"}>
                            {record?.bank_account?.bank_number}{" "}
                            {record?.bank_account?.account_number}
                          </p>
                        </div>
                      </RawCell>
                      <ActionButtonsCell className="white-row">
                        <ActionButton
                          className={"red margin-left-auto"}
                          id={"cancel-action-queue-request-" + record?.uuid}
                          key={"cancel-action-queue-request-" + record?.uuid}
                          callback={() =>
                            openPurchaseCancelModalOpen(record?.uuid)
                          }
                        >
                          <i
                            style={{ marginRight: "5px" }}
                            className="fa-solid fa-xmark"
                          ></i>
                          Cancel
                        </ActionButton>
                      </ActionButtonsCell>
                    </>
                  ) : (
                    <div></div>
                  )}
                </BasicRow>
              ))}
            </Table>

            <h2>Investment Ownership</h2>
            <div className="divider" />
            <Table
              className={`primary investment-table ${
                ownershipCertificates ? "" : ""
              }`}
            >
              <CellHeader className="table-header">
                <RawCell className="black-bold">Investment ID</RawCell>
                <RawCell className="black-bold">Type</RawCell>
                <RawCell className="black-bold">Units</RawCell>
                <RawCell className="black-bold">Rate</RawCell>
                <RawCell className="black-bold">Renewal Date</RawCell>
                <RawCell className="black-bold max-w-160">
                  Borrower Next Payment Date
                </RawCell>
                <RawCell className="black-bold">Ownership</RawCell>
                <RawCell></RawCell>
              </CellHeader>

              {ownershipCertificates?.map((record: any) => (
                <BasicRow
                  className="table-rows"
                  key="ownership_certificate_records"
                >
                  <TextCell className="white-row">
                    <span className="black-text">
                      {record?.investment?.friendly_id}
                    </span>
                  </TextCell>
                  <TextCell colSpan={1} className="white-row">
                    {record?.investment?.type_label}
                  </TextCell>
                  <TextCell className="white-row">
                    <span data-dd-privacy="mask" className="black-text">
                      $
                      {formatNumberToMoneyFormat(
                        unitsDividedByMultiplier(
                          record?.units,
                          record?.investment?.units_multiplier
                        )
                      )}
                    </span>
                  </TextCell>
                  <TextCell className="white-row">
                    <span className={"data-dog-wrap"} data-dd-privacy="mask">
                      {calcInvestorRate(
                        record?.investment?.interest_rate,
                        record?.investment?.servicing_rate
                      )}
                      %
                    </span>
                  </TextCell>
                  <TextCell className="white-row">
                    {record?.investment?.maturity_date
                      ? formatDateFromString(record?.investment?.maturity_date)
                      : "Pending"}
                  </TextCell>
                  <TextCell className="white-row">
                    {record?.investment?.next_payment_date
                      ? formatDateFromString(
                          record?.investment?.next_payment_date
                        )
                      : "Pending"}
                  </TextCell>
                  <RawCell className="white-row">
                    <div
                      key={record?.uuid + "percent-bar-component"}
                      style={{ marginBottom: "5px" }}
                    >
                      {getPercent(
                        record?.units,
                        record?.investment?.units_available,
                        false
                      )}
                      %
                    </div>
                    <ProgressBar
                      key={record?.uuid + "progress-bar-component"}
                      active={
                        unitsDividedByMultiplier(
                          record?.units,
                          record?.investment?.units_multiplier
                        ) ?? 0
                      }
                      total={
                        unitsDividedByMultiplier(
                          record?.investment?.units_available,
                          record?.investment?.units_multiplier
                        ) ?? 0
                      }
                      showMax={false}
                      showText={false}
                    />
                  </RawCell>
                  <ActionButtonsCell className="white-row">
                    <ActionButton
                      id={"cancel"}
                      key={"cancel-"}
                      callback={() =>
                        investmentDetails(record?.investment?.uuid)
                      }
                    >
                      Details
                    </ActionButton>
                  </ActionButtonsCell>
                </BasicRow>
              ))}
            </Table>

            <h2>Transactions</h2>
            <div className="divider" />
            <Transactions
              investment_account={investmentAccount}
              transactions={transactions}
              friendlly_id={investmentFriendlyId}
            />

            <div className="footer-content"></div>
          </div>
        </div>
        <div className="footer">
          <div className="footer__phone-link">
            Need help?
            <PhoneLink />
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestmentAccount;
