import { Link, Outlet, useLocation } from "react-router-dom";
import { Notifications } from "@truenorthmortgage/olympus";
import NotificationComponent from "./components/NotificationComponent";
import { PhoneLink } from "./components";
import { FormattedMessage } from "react-intl";

const Layout = () => {
  const location = useLocation();

  const breadcrumbs = [{ label: "Dashboard", path: "/" }];
  const path_acc = "";
  const paths = location.pathname.split("/");

  const breadcrumbLabel = (path: string, loanNumber: string | null) => {
    return {
      heloc: `HELOC ${loanNumber}`,
      concierge: `Concierge ${loanNumber}`,
      mortgage: `Mortgage ${loanNumber}`,
      account: "My Account",
    }[path];
  };
  paths.forEach((pathString: string, i) => {
    const loanNumber = ["heloc", "mortgage", "concierge"].includes(pathString)
      ? paths[i + 1]
      : null;

    const path =
      path_acc + "/" + pathString + (loanNumber ? `/${loanNumber}` : "");

    const label = breadcrumbLabel(pathString, loanNumber);

    if (label && pathString && i + 1 === paths.length) {
      breadcrumbs.push({ label, path: path + location.search });
    } else if (label && pathString) {
      breadcrumbs.push({ label, path });
    }
  });

  return (
    <>
      <div id="main">
        <Notifications
          timeout={15000}
          customNotificationElement={<NotificationComponent />}
        />
        <div className="main-header"></div>
        <div className="main-content login">
          <Outlet />
        </div>
      </div>
      <div className="footer login-page">
        <div className="footer__phone-link">
          Need help?
          <PhoneLink />
        </div>
        <Link className={"forgot-password"} to="/account/forgot-password">
          <FormattedMessage id="Forgot Password?" />
        </Link>
      </div>
    </>
  );
};

export default Layout;
