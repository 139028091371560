import { Link } from "react-router-dom";

const BreadcrumbsComponent = (props: any) => {
  return (
    <div id="breadcrumbs">
      {props.items.map((item: any, i: number) => (
        <span key={item.path}>
          <Link to={item.path}>{item.label}</Link>{" "}
          {i + 1 < props.items.length ? (
            <i className="fa fa-chevron-right" />
          ) : (
            ""
          )}
        </span>
      ))}
    </div>
  );
};

export default BreadcrumbsComponent;
