const en = {
  "Investor Account Details": "Investor Account Details",
  "Edit Nickname": "Edit Nickname",
  "Investment Listings": "Investment Listings",
  "Investor Account Details:": "Investor Account Details",
  "Investment Listings:": "Investment Listings",
  "Confirm Existing Username:": "Confirm Existing Username:",
  "New Username:": "New Username:",
  "Confirm New Username:": "Confirm New Username:",
  "Current Email Address": "Current Email Address",
  "New Email:": "New Email:",
  "Confirm Email:": "Confirm Email:",
  "Withdraw Funds": "Withdraw Funds",
  "Add Funds": "Add Funds",
  Cancel: "Cancel",
  Update: "Update",
  Submit: "Submit",
  "My Account Options": "My Account Options",
  "User Details": "User Details",
  "Document Delivery": "Document Delivery",
  "Mailing Address": "Mailing Address",
  "Mail Documents": "Mail Documents",
  "Electronic Communications": "Electronic Communications",
  "Contact THINK Financial": "Contact THINK Financial",
  "Change My Password": "Change My Password",
  "Update Account Details": "Update Account Details",
  "Good {timeOfDay} {name}": "Good {timeOfDay} {name}",
  Morning: "Morning",
  Afternoon: "Afternoon",
  Evening: "Evening",
  Options: "Options",
  "Home Equity Line Of Credit": "Home Equity Line Of Credit",
  "Read New Messages": "Read New Messages",
  "My THINK Account": "My THINK Account",
  "Update My Contact Details": "Update My Contact Details",
  "Funds Available": "Funds Available",
  "Next Payment Date": "Next Payment Date",
  "HELOC Actions": "HELOC Actions",
  "HELOC Balance": "HELOC Balance",
  "Property Info Details": "Property Info Details",
  "Withdraw Funds From HELOC": "Withdraw Funds From HELOC",
  "Make Payment To HELOC": "Make Payment To HELOC",
  "Change Date of Payments": "Change Date of Payments",
  "Set Up Recurring Payments": "Set Up Recurring Payments",
  "Payout HELOC": "Payout HELOC",
  "Please Log In": "Please Log In",
  Email: "Email",
  Password: "Password",
  "Confirm Password": "Confirm Password",
  "Remember Me": "Remember Me",
  Login: "Login",
  "Confirm Existing Password:": "Confirm Existing Password:",
  "New Password:": "New Password:",
  "Confirm New Password:": "Confirm New Password:",
  "Existing Accounts": "Existing Accounts",
  "Mortgage Requests": "Mortgage Requests",
  "HELOC Requests": "HELOC Requests",
  Mail: "Mail",
  "Contact Us": "Contact Us",
  "A request may take up to five days to process":
    "A request may take up to five days to process",
  "Withdraw from HELOC": "Withdraw from HELOC",
  "Deposit into account:": "Deposit into account:",
  Amount: "Amount",
  "Choose Payment Source:": "Choose Payment Source:",
  "Choose Payment Date:": "Choose Payment Date:",
  "Change Password": "Change Password",
  "Edit My Contact Details": "Edit My Contact Details",
  "An adjustment statement will be added to your":
    "An adjustment statement will be added to your",
  "documents section": "documents section",
  "Your next regular payment must complete the current payment schedule.":
    "Your next regular payment must complete the current payment schedule.",
  "If you are concerned about making your next payment, please phone us as soon as possible.":
    "If you are concerned about making your next payment, please phone us as soon as possible.",
  "Request Payment Date Change": "Request Payment Date Change",
  "Requested New Payment Date:": "Requested New Payment Date:",
  "Current Payment Date": "Current Payment Date",
  interestpayment: "Interest",
  borrowerpayment: "Payment",
  borrowerwithdrawal: "Withdrawal",
  payoutmortgage: "HELOC Payout",
  importmortgage: "HELOC Import",
  createmortgage: "Loan Setup",
  principal: "Principal",
  monthly: "Monthly",
  weekly: "Weekly",
  MONTHLY: "Monthly",
  SEMI_MONTHLY: "Semi-Monthly",
  WEEKLY: "Weekly",
  BIWEEKLY: "Biweekly",
  "Create Password": "Create Password",
  "Forgot Password?": "Forgot Password?",
  "Forgot Password": "Forgot Password",
  "Set Up Recurring Payment": "Set Up Recurring Payment",
  "Pending Requests": "Pending Requests",
  "Upcoming Transactions": "Upcoming Transactions",
  "Past Transactions": "Past Transactions",
  "Recurring Payments": "Recurring Payments",
  CREATEWITHDRAWAL: "Withdrawal",
  CREATEPAYMENT: "Payment",
  CREATERECURRINGPAYMENT: "Recurring Payment",
  CANCELRECURRINGPAYMENT: "Cancel Recurring Payment",
  "Payment Amount": "Payment Amount",
  "Start Date": "Start Date",
  "End Date": "End Date",
  Frequency: "Frequency",
  "Cancel Change Request": "Cancel Change Request",
  "Cancel Recurring Payment": "Cancel Recurring Payment",
  "Are you sure you want to cancel this change request?":
    "Are you sure you want to cancel this change request?",
  "Not Found": "Not Found",
  "Sorry, we could not find the view you are looking for.":
    "Sorry, we could not find the view you are looking for.",
  "Back to Home": "Back to Home",
  Concierge: "Concierge",
  "-- Select a Frequency --": "-- Select a Frequency --",
  "Make Payment": "Make Payment",
  Actions: "Actions",
  "Loan Balance": "Loan Balance",
  "Property Details": "Property Details",
  interest: "interest",
  Continue: "Cotinue",
  "Send Reset Email": "Send Reset Email",
  "Reset Password": "Reset Password",
  "Set New Password": "Set New Password",
  "A request may take up to two business days to process":
    "A request may take up to two business days to process",
  "Forgot Your Password?": "Forgot Your Password?",
  "Check Your Email": "Check Your Email",
  "Expired Link": "Expired Link",
  "Set Your Account Password": "Set Your Account Password",
};

export default en;
