import { useState, useEffect } from "react";
import { SERVICES } from "../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { utils, Notifications } from "@truenorthmortgage/olympus";
import Loading from "../components/Loading";
import NavComponent from "../components/NavComponent";
import NotificationComponent from "../components/NotificationComponent";
import { PhoneLink } from "../components";
import Map from "../components/Map";
import { calcEffectiveInvestorRate, formatNumberToMoneyFormat } from "../utils";
import { InvestmentListingsService } from "../services/investment-listings-service";
import BreadcrumbsComponent from "../components/BreadcrumbsComponent";
import { formatDateFromString } from "../utils";
import KratosModal from "../components/Modals/KratosModal";
import PurchaseModal from "../components/Modals/ActionQueue/PurchaseModal";
import { useSearchParams } from "react-router-dom";

const InvestmentListings = () => {
  const investmentListingService =
    utils.injection.useInjection<InvestmentListingsService>(
      SERVICES.InvestmentListingService
    );

  const [investmentListings, setInvestmentListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowBackgroundColour] = useState("investment-listing-grey");

  const showHideModal = (val: any) => {
    // Change the Icon.
    const plus = document.getElementById(val + "-plus");

    // Add the animation here !
    if (plus) {
      if (plus.classList.contains("rotated")) {
        plus.classList.remove("rotated");
      } else {
        plus.classList.add("rotated");
      }
    }

    // Remove the padding
    const row = document.getElementById(val + "-row");
    if (row) {
      if (row.classList.contains("has-padding")) {
        row.classList.remove("has-padding");
        row.classList.add("top-padding-row");
      } else {
        row.classList.add("has-padding");
        row.classList.remove("top-padding-row");
      }
    }

    // The collapse Content !
    const collapse = document.getElementById(val + "-content");
    if (collapse) {
      if (collapse.classList.contains("closed")) {
        collapse.classList.remove("closed");
        collapse.classList.add("open");
      } else {
        collapse.classList.add("closed");
        collapse.classList.remove("open");
      }
    }
    // Change the row colour
    const header = document.getElementById(val + "-header");

    setTimeout(function () {
      if (header) {
        const childrenArray = Array.from(header.children);

        if (header.classList.contains("remove-bottom-header-padding")) {
          header.classList.remove("remove-bottom-header-padding");
        } else {
          header.classList.add("remove-bottom-header-padding");
        }

        childrenArray.forEach((child) => {
          // Do something with each child element
          if (child.classList.contains("investment-listing-grey")) {
            child.classList.remove("investment-listing-grey");
            child.classList.add("investment-listing-purple");
          } else {
            child.classList.remove("investment-listing-purple");
            child.classList.add("investment-listing-grey");
          }
        });
      }

      if (plus) {
        plus.classList.add("rotate");

        if (plus.classList.contains("white-font")) {
          plus.classList.remove("white-font");
        } else {
          plus.classList.add("white-font");
        }
      }
    }, 500);
  };

  const breadcrumbs = [
    { label: "Dashboard", path: "/dashboard" },
    {
      label: "Investment Listings",
      path: "/investment-listings/",
    },
  ];

  const [investment_listing] = useSearchParams();

  // IF we are redirecting from the investment page to open the corresponding listing !
  useEffect(() => {}, []);

  // Store Entire BreadCrumb
  localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));

  // Grab the Investment Listing Data
  useEffect(() => {
    investmentListingService
      .getInvestmentListings()
      .then((data) => {
        setIsLoading(false);
        setInvestmentListings(data?.investment_listings);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (investment_listing !== null && investment_listing !== undefined) {
      showHideModal(investment_listing.get("investment_listing"));
    }
  }, [investmentListings]);

  // Open the Investment Purchase Modal !
  const [isActionQueuePurchaseOpen, setIsActionQueuePurchaseOpen] =
    useState(false);

  const closeActionQueuePurchase = () => {
    setIsActionQueuePurchaseOpen(false);
  };

  const [investmentListingObject, setInvestmentListingObject] = useState({});

  const openPurchaseModal = (val: any) => {
    setIsActionQueuePurchaseOpen(true);
    setInvestmentListingObject(investmentListings[val]);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <KratosModal
        is_open={isActionQueuePurchaseOpen}
        callback={closeActionQueuePurchase}
      >
        <PurchaseModal
          callback={closeActionQueuePurchase}
          investment_listing_object={investmentListingObject}
        />
      </KratosModal>

      <div className="dashboard-container">
        <NavComponent />
      </div>
      <div id="main" className="dashboard-content">
        <Notifications
          timeout={15000}
          customNotificationElement={<NotificationComponent />}
        />
        <div className="main-header">
          <BreadcrumbsComponent items={breadcrumbs} />
        </div>
        <div className="main-content">
          <div className="dashboard investment-listings-container">
            <h1
              style={{ textTransform: "capitalize", marginBottom: "3px" }}
              className={"mobile-header"}
            >
              Investment Listings
            </h1>

            <div className="primary investment-table investment-details-table investment-listings-table">
              <div className="investment-listing-header">
                <div className={"raw-cell grade"}>
                  <span>Grade</span>
                </div>
                <div className={"raw-cell rate"}>
                  <span>Rate</span>
                </div>
                <div className={"raw-cell term-left"}>
                  <span>Term Left</span>
                </div>
                <div className={"raw-cell property "}>
                  <span>Property Use</span>
                </div>
                <div className={"raw-cell location"}>
                  <span>Location</span>
                </div>
                <div className={"raw-cell amount hide-on-mobile"}>
                  <span>Available</span>
                </div>
                <div className={"raw-cell spacer hide-on-mobile"}>
                  <span></span>
                </div>
              </div>
              {Object.entries(investmentListings).map(
                ([key, value]: [string, any]) => (
                  <React.Fragment
                    key={"main-row-" + value?.investment?.uuid + Math.random()}
                  >
                    <div
                      className={"basic-row wrap-collapsible"}
                      key={"investment-listing-row-" + value?.investment?.uuid}
                      id={key + "-header"}
                      onClick={() => showHideModal(key)}
                    >
                      <div
                        className={`raw-cell header-main-row ${rowBackgroundColour}`}
                      >
                        <div>
                          <span
                            className={
                              "grade grade-" +
                              value?.investment?.investment_grade.toLowerCase()
                            }
                          >
                            <div className="inner-wrap">
                              <span
                                className={
                                  "grade-" +
                                  value?.investment?.investment_grade.toLowerCase()
                                }
                              >
                                <i className="fa fa-circle background-circle-fa">
                                  <span>
                                    {value?.investment?.investment_grade}
                                  </span>
                                </i>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div
                        className={`raw-cell header-main-row ${rowBackgroundColour} rate`}
                      >
                        <span className="rate bold">
                          {calcEffectiveInvestorRate(
                            false,
                            Number(value?.investment?.interest_rate),
                            Number(value?.investment?.servicing_rate),
                            Number(value?.listing?.return_rate_modifier_bps)
                          )}
                          %
                        </span>
                        <span className={"ltv hide-on-mobile hide-on-tablet"}>
                          <span
                            className={"bold small-header"}
                            style={{ paddingRight: "2px" }}
                          >
                            LTV:{" "}
                          </span>
                          <span className={"small-header"}>
                            {" " +
                              value?.investment_details?.weighted_ltv +
                              "%"}
                          </span>
                        </span>
                      </div>
                      <div
                        className={`raw-cell header-main-row ${rowBackgroundColour} term-left`}
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "initial",
                        }}
                      >
                        <span className="term_left">
                          {value?.investment?.term_left?.years > 0
                            ? value?.investment?.term_left?.years +
                              " yr" +
                              (value?.investment?.term_left?.months > 0
                                ? ", "
                                : "")
                            : ""}
                          {value?.investment?.term_left?.months > 0
                            ? value?.investment?.term_left?.months +
                              " mo" +
                              (value?.investment?.term_left?.days > 0
                                ? ", "
                                : "")
                            : ""}
                          {value?.investment?.term_left?.days > 0
                            ? value?.investment?.term_left?.days + " days"
                            : ""}
                        </span>
                        <span
                          className={"term_date"}
                          style={{ marginTop: "5px", color: "black" }}
                        >
                          {formatDateFromString(
                            value?.investment?.maturity_date
                          )}
                        </span>
                      </div>
                      <div
                        className={`raw-cell double-width-item header-main-row ${rowBackgroundColour} friendly-id`}
                      >
                        <span className={"property-use capitalize"}>
                          {value?.property?.property_use}
                        </span>
                        <span
                          className={
                            "friendly_id hide-on-mobile hide-on-tablet"
                          }
                        >
                          {value?.listing?.friendly_id}
                        </span>
                      </div>
                      <div
                        className={`raw-cell double-width-item header-main-row ${rowBackgroundColour}  location`}
                      >
                        <span>
                          {value?.property?.city}
                          {","} {value?.property?.province}
                        </span>
                      </div>
                      <div
                        className={`raw-cell header-main-row ${rowBackgroundColour} investment-progress hide-on-mobile`}
                      >
                        <span className={"progress-wrapper"}>
                          <span className="modal-header-width">
                            $
                            {formatNumberToMoneyFormat(
                              Number(
                                value?.listing?.units_available_to_be_sold ??
                                  0.0
                              )
                            )}
                          </span>
                          <span
                            style={{ flexDirection: "column" }}
                            className="header-progress-wrap"
                          >
                            <span
                              className="header-progress"
                              style={{
                                backgroundColor: "#D9D9D9",
                                width: "100%",
                              }}
                            >
                              <span
                                className="progress"
                                style={{
                                  width: `${
                                    ((value?.listing
                                      ?.units_available_to_be_sold ?? 0) /
                                      (value?.listing?.units_listed ?? 0)) *
                                    100
                                  }%`,
                                }}
                              ></span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        className={`raw-cell header-main-row ${rowBackgroundColour} accordion-icon`}
                      >
                        <FontAwesomeIcon
                          className="background-circle"
                          icon={["far", "circle"]}
                        />
                        <FontAwesomeIcon
                          icon="minus"
                          className={"hidden"}
                          id={key + "-minus"}
                        />
                        <FontAwesomeIcon
                          icon={"plus"}
                          id={key + "-plus"}
                          className={"rotate"}
                        />
                      </div>
                    </div>
                    <div
                      className={"modal-content"}
                      key={
                        "investment-listing-content-" + value?.investment?.uuid
                      }
                    >
                      <div id={key + "-row"} className={"raw-cell has-padding"}>
                        <div
                          key="investment-listing-row-"
                          id={key + "-content"}
                          className="accordion-items closed content"
                        >
                          <div className="accordion-map">
                            <Map
                              key={value?.investment?.uuid}
                              map_api_key={
                                window.REACT_APP_GOOGLE_MAPS_JS_API_KEY
                              }
                              latitude={Number(
                                value?.property?.neighbourhood_lat
                              )}
                              longitude={Number(
                                value?.property?.neighbourhood_lon
                              )}
                              map_id={value?.investment?.uuid + Math.random()}
                            />
                            <div className="content-container">
                              <div className="content-wrap">
                                <div className="left-side">
                                  <h4 className="no-left-margin">Grade</h4>
                                  <div className="bar-wrap">
                                    <div className="vertical-bar">
                                      <div className="top-ball"></div>
                                      <div className="bottom-ball"></div>
                                    </div>
                                    <h4>Location</h4>
                                    <h4>Security</h4>
                                    <h4>Property Use</h4>
                                    <h4>Weighted LTV</h4>
                                    <h4>Credit Score</h4>
                                  </div>
                                </div>
                                <div className="right-side">
                                  <span
                                    className={
                                      "grade grade-" +
                                      value?.investment?.investment_grade.toLowerCase()
                                    }
                                  >
                                    <div className="inner-wrap">
                                      <span
                                        className={
                                          "grade-" +
                                          value?.investment?.investment_grade.toLowerCase()
                                        }
                                      >
                                        <i className="fa fa-circle background-circle-fa">
                                          <span>
                                            {
                                              value?.investment
                                                ?.investment_grade
                                            }
                                          </span>
                                        </i>
                                      </span>
                                    </div>
                                  </span>
                                  <div className="inner-content-wrap">
                                    <h4>
                                      {value?.property?.city},{" "}
                                      {value?.property?.province}
                                    </h4>
                                    <h4 className="capitalize">
                                      {value?.investment_details?.security}
                                    </h4>
                                    <h4 className="capitalize">
                                      {value?.property?.property_use}
                                    </h4>
                                    <h4>
                                      {value?.investment_details?.weighted_ltv}%
                                    </h4>
                                    <div className="score-wrap">
                                      <i className="fa fa-signal credit-bars"></i>{" "}
                                      <span className="credit-span">
                                        {Array.isArray(value?.borrowers) ===
                                          true &&
                                        value?.borrowers[0]?.credit_score
                                          ? value?.borrowers[0]?.credit_score
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lower-content-container">
                                <div className="left-side">
                                  <h4>Rate</h4>
                                  <h4>Property Type</h4>
                                  <h4>Renewal Date</h4>
                                  <h4>Amount Available</h4>
                                </div>
                                <div className="right-side">
                                  <h4>
                                    {value?.effective_investor_rate.toFixed(2)}%
                                  </h4>
                                  <h4 className="capitalize">
                                    {value?.property?.property_type}
                                  </h4>
                                  <h4>
                                    {value?.investment_details?.maturity_date
                                      ? value?.investment_details?.maturity_date.replace(
                                          /00:00:00/,
                                          ""
                                        )
                                      : ""}
                                  </h4>
                                  <h4>
                                    $
                                    {formatNumberToMoneyFormat(
                                      Number(
                                        value?.listing
                                          ?.units_available_to_be_sold ?? 0
                                      )
                                    )}{" "}
                                    of $
                                    {formatNumberToMoneyFormat(
                                      Number(value?.listing?.units_listed)
                                    )}
                                  </h4>
                                </div>
                              </div>
                              <div className="progress-investment-wrap">
                                <div
                                  className="progress-investment"
                                  style={{
                                    width:
                                      ((value?.listing
                                        ?.units_available_to_be_sold ?? 0) /
                                        (value?.listing?.units_listed ?? 0)) *
                                        100 +
                                      "%",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div className="owner-profile-wrap">
                            <h2 className="owner-profile-header">
                              Owner Profile
                            </h2>
                            <div className="profile-container">
                              <p>
                                {Array.isArray(value?.notes) === true &&
                                value?.notes[0]?.note
                                  ? value?.notes[0]?.note
                                  : "No ownership details exist for this borrower."}
                              </p>
                            </div>
                          </div>

                          <div className="purchase-investment-wrap">
                            <button
                              type="button"
                              style={{ height: 40 }}
                              className="button button primary"
                              onClick={() =>
                                openPurchaseModal(value?.listing?.uuid)
                              }
                            >
                              Invest
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"investment-listing-spacer"}></div>
                  </React.Fragment>
                )
              )}
            </div>

            <div className="footer-content"></div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer__phone-link">
          Need help?
          <PhoneLink />
        </div>
      </div>
    </>
  );
};

export default InvestmentListings;
