import { FC, useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";

export type MapProps = {
  map_api_key: any;
  latitude: number;
  longitude: number;
  map_id: any;
};

const Map: FC<MapProps> = ({ map_api_key, latitude, longitude, map_id }) => {
  const [googleMap, setMap] = useState() as any;
  const [mapMarker, setMarker] = useState() as any;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loader = new Loader({
      apiKey: map_api_key,
      version: "weekly",
    });

    let map: google.maps.Map;

    loader.load().then(async () => {
      const { Map } = (await google.maps.importLibrary(
        "maps"
      )) as google.maps.MapsLibrary;

      // Fix the maps to reduce call volumes !
      if (loaded == false) {
        map = new Map(document.getElementById("map-" + map_id) as HTMLElement, {
          center: { lat: latitude, lng: longitude },
          zoom: 14,
          disableDefaultUI: true,
          keyboardShortcuts: false,
          draggable: false,
          scrollwheel: false,
        });
        setMap(map);
        setLoaded(true);
      }

      if (mapMarker == undefined) {
        const marker = new google.maps.Circle({
          center: { lat: latitude, lng: longitude },
          strokeColor: "#6f4f9e",
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: "#6f4f9e",
          fillOpacity: 0.5,
          map: map,
          radius: 800,
        });
        setMarker(marker);

        // Removed the drag
      }

      if (mapMarker != undefined) {
        mapMarker.setPosition({ lat: latitude, lng: longitude });
        googleMap.panTo({ lat: latitude, lng: longitude });
      }
    });
  }, [latitude, longitude]);

  return (
    <>
      <div id={"map-" + map_id} className={"property-map"} />
    </>
  );
};

export default Map;
