import React, { FC, useState } from "react";
import { formatNumberToMoneyFormat } from "../utils";
import { dateToYYYYMMDD, unitsDividedByMultiplier } from "../utils";
import Map from "../components/Map";

export type InvestmentListingInfoProps = {
  investment?: any;
  investment_details?: any;
  property?: any;
  borrowers?: any;
  listing?: any;
  notes?: any;
};

const InvestmentListingInfo: FC<InvestmentListingInfoProps> = ({
  investment = null,
  investment_details = null,
  property = null,
  borrowers = null,
  listing = null,
  notes = null,
}) => {
  const [latitude] = useState(property?.neighbourhood_lat);
  const [longitude] = useState(property?.neighbourhood_lon);
  const [effectiveInvestorRate] = useState(
    listing[listing.length - 1]?.effective_investor_rate
  );
  const maturityDate = dateToYYYYMMDD(investment_details?.maturity_date);
  const [progressWidth] = useState(
    ((listing[listing.length - 1]?.unitsAvailableToBeSold ?? 0) /
      (listing[listing.length - 1]?.units_listed ?? 0)) *
      100
  );

  return (
    <>
      <div key="investment-listing-row-" id={"-content"}>
        <div className="accordion-map">
          <Map
            map_api_key={window.REACT_APP_GOOGLE_MAPS_JS_API_KEY}
            latitude={Number(latitude)}
            longitude={Number(longitude)}
            map_id={investment?.uuid}
          />
          <div className="content-container">
            <div className="content-wrap">
              <div className="left-side">
                <h4 className="no-left-margin">Grade</h4>
                <div className="bar-wrap">
                  <div className="vertical-bar">
                    <div className="top-ball"></div>
                    <div className="bottom-ball"></div>
                  </div>
                  <h4>Location</h4>
                  <h4>Security</h4>
                  <h4>Property Use</h4>
                  <h4>Weighted LTV</h4>
                  <h4>Credit Score</h4>
                </div>
              </div>
              <div className="right-side">
                <span
                  className={
                    "grade grade-" + investment?.investment_grade.toLowerCase()
                  }
                >
                  <div className="inner-wrap">
                    <span
                      className={
                        "grade-" + investment?.investment_grade.toLowerCase()
                      }
                    >
                      <i className="fa fa-circle background-circle-fa">
                        <span>{investment?.investment_grade}</span>
                      </i>
                    </span>
                  </div>
                </span>
                <div className="inner-content-wrap">
                  <h4>{property?.neighbourhood}</h4>
                  <h4 className="capitalize">{investment_details?.security}</h4>
                  <h4 className="capitalize">
                    {property?.property_use.replace(/_/g, " ")}
                  </h4>
                  <h4>{investment_details?.weighted_ltv}%</h4>
                  <div className="score-wrap">
                    <i className="fa fa-signal credit-bars"></i>{" "}
                    <span className="credit-span">
                      {Array.isArray(borrowers) === true &&
                      borrowers[0]?.credit_score
                        ? borrowers[0]?.credit_score
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="lower-content-container">
              <div className="left-side">
                <h4>Rate</h4>
                <h4>Property Type</h4>
                <h4>Renewal Date</h4>
                <h4>Amount Available</h4>
              </div>
              <div className="right-side">
                <h4>{effectiveInvestorRate}%</h4>
                <h4 className="capitalize">{property?.property_type}</h4>
                <h4>{maturityDate}</h4>
                <h4>
                  $
                  {formatNumberToMoneyFormat(
                    unitsDividedByMultiplier(
                      Number(
                        listing[listing.length - 1]?.unitsAvailableToBeSold ?? 0
                      ),
                      Number(
                        listing[listing.length - 1]?.investment
                          ?.units_multiplier ?? 0
                      )
                    )
                  )}{" "}
                  of $
                  {formatNumberToMoneyFormat(
                    unitsDividedByMultiplier(
                      Number(listing[listing.length - 1]?.units_listed),
                      Number(
                        listing[listing.length - 1]?.investment
                          ?.units_multiplier
                      )
                    )
                  )}
                </h4>
              </div>
            </div>
            <div className="progress-investment-wrap">
              <div
                className="progress-investment"
                style={{
                  width: progressWidth < 0 ? "0%" : progressWidth + "%",
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="owner-profile-wrap">
          <h2 className="owner-profile-header">Owner Profile</h2>
          <div className="profile-container">
            <div>
              <p>
                {Array.isArray(notes) === true && notes[0]?.note
                  ? notes[0]?.note
                  : "No ownership details exist for this borrower."}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestmentListingInfo;
