export const ERROR = {
  BAD_REQUEST: {
    value: "400",
    label:
      "There was a problem with your request. Please check your input and try again.",
  },
  UNAUTHORIZED: {
    value: "401",
    label:
      "You need to log in or provide valid credentials to access the requested resource.",
  },
  FORBIDDEN: {
    value: "403",
    label:
      "Sorry, but you don't have permission to access this resource. Please contact the administrator for assistance.",
  },
  NOT_FOUND: {
    value: "404",
    label:
      "The page or resource you're looking for couldn't be found. Please verify the URL or try searching again.",
  },
  INTERNAL_SERVER_ERROR: {
    value: "500",
    label:
      "Something went wrong on our end. Our team has been notified, and we're working to fix the issue. Please try again later.",
  },
};
